import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class SamsungBrowserGuard implements CanActivate {

    constructor(private router: Router) { }

    canActivate(): boolean {
        const userAgent: string = window.navigator.userAgent;
        if (userAgent.includes('SamsungBrowser') && userAgent.includes('Mobile')) {
            this.router.navigate(['']);
            return false;
        }
        return true;
    }
}
