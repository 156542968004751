import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'informations-generales',
  templateUrl: './informations-generales.component.html',
  styleUrls: ['./informations-generales.component.css'],
})
export class InformationsGeneralesComponent {
  constructor(private router: Router) {}
}
