/* export const SERVER_API = "http://localhost:8081/api/"; */
//export const SERVER_API = "https://172.16.202.220/be-onbkb/api/";
//export const SERVER_API = "http://10.110.101.80:8081/be-onbkb/api/";
//export const SERVER_API = "http://10.110.101.246:8080/be-onbkb-test/api/";
/* export const SERVER_API =
  "https://devenirclientbmcecapitalbourse.com/be-onbkb/api/"; */

//reverseProxy config
export const SERVER_API = "/api/";
export const QUESTIONNAIRE_API = "questionnaire/";
export const HTTP_RESSOURCES_ALREADY_EXISTS = 409;
export const HTTP_RESSOURCES_UNAUTHORIZED = 401;

export const WORKFLOW_STEPS = {
  CHECK_CONTRAT: "checkContrat",
  CHECK_CONTRAT_MANDATAIRE: "checkContratMandataire",
  CATEGORIES: "categories",
  CATEGORIES_MANDATIRE: "categoriesMandatire",
  SERVICES: "services",
  RDV_DATE: "rdv_date",
  ELIGIBILITE: "eligibilite",
  BIENVENUE_BK: "bienvenueBk",
  SIGNE_CONTRAT: "signeContrat",
  SIGNE_CONTRAT_MANDATAIRE: "signeContratMandatire",
};

export const CIN_INDEX = 0;
export const SELFIE_INDEX = 1;
export const GENERALITE_INDEX = 2;
export const COORDONNEE_INDEX = 3;
export const JOB_INDEX = 4;
export const BANK_INDEX = 5;
export const ACTIVITY_INDEX = 6;
export const DEPOSITORY_INDEX = 7;
export const FATCA_INDEX = 8;
export const PPE_INDEX = 9;
export const FINANCIAL_SITUATION_INDEX = 10;
export const INVESTEMENT_KNOWLEDGE_INDEX = 11;
export const OFFRED_SERVICES_INDEX = 12;

export const SIDE_NAV_TEXT = {
  [WORKFLOW_STEPS.BIENVENUE_BK]: ["Bienvenue à BMCE Capital bourse !"],
  [WORKFLOW_STEPS.SERVICES]: ["Veuillez selectionner un ou plusieurs services"],
  [WORKFLOW_STEPS.CHECK_CONTRAT]: [
    "Veuillez lisez et validez votre questionnaire",
    "Veuillez lisez et validez votre convention",
  ],
  [WORKFLOW_STEPS.CHECK_CONTRAT_MANDATAIRE]: [
    "Veuillez lisez et validez votre questionnaire",
    "Veuillez lisez et validez le questionnaire de votre mandataire",
    "Veuillez lisez et validez votre convention",
  ],

  [WORKFLOW_STEPS.SIGNE_CONTRAT]: [
    "Signer votre questionnaire",
    "Signer votre convention",
  ],
  [WORKFLOW_STEPS.SIGNE_CONTRAT_MANDATAIRE]: [
    "Signer votre questionnaire",
    "Signer le questionnaire de votre mandataire",
    "Signer votre convention",
  ],
};
