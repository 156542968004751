<div align="center" style="width: 100%; height: 100%; background: white">
  <div align="center">
    <div class="landingpage-container">
      <header
        role="banner"
        class="c-page-header c-page-header--landing"
        ng-class="{ 'c-page-header--landing': isLanding }"
      >
        <div
          ng-if="isLanding"
          class="container c-page-header__container u-nopadding"
        >
          <div class="c-page-header__logo-container">
            <div
              class="c-page-header__logo-container-cell c-page-header__logo-container-cell--landing"
            >
              <a
                class="c-page-header__logo-landing"
                title="BK Banque"
                href="https://www.BK-banque.com"
              >
                <img
                  class="img-logo-land-page"
                  height="50"
                  alt="BK Banque"
                  src="assets/img/thumbnail_LOGO-bkb-250x50.png"
                />
              </a>
            </div>

            <div
              class="c-page-header__logo-container-cell c-page-header__logo-container-cell--landing"
            >
              <h1
                style="
                  font: 400 23px/32px Roboto, 'Helvetica Neue', sans-serif;
                  font-weight: bold;
                  color: #888;
                "
              >
                OUVERTURE DE VOTRE
                <strong style="color: var(--boa-blue)"
                  >COMPTE BOURSE EN LIGNE</strong
                >
              </h1>
            </div>
          </div>

          <div class="row" style="float: right">
            <div class="col-sm-7 col-sm-offset-5 text-right"></div>
          </div>
        </div>
      </header>
      <main>
        <div class="page-main">
          <div class="row btm-detail-txt">
            <div class="col-md-12 btm-detail-txt-1">
              <p style="text-align: justify">
                Les données recueillies dans le cadre de votre demande
                d'ouverture de compte ont un caractère obligatoire et sont
                indispensables pour gérer votre demande. Elles font l'objet d'un
                traitement automatique, par BK et ses sous-traitants, uniquement
                à des fins de finalisation de votre demande. Vos données
                pourront être conservées pendant une durée maximale d'un an à
                des fins probatoires. A l'issue de cette période, elles seront
                anonymisées et conservées à des fins statistiques et d'étude.
              </p>
              <p style="margin-top: 10px; text-align: justify">
                Dans le cadre de vos échanges avec BK, vos conservations
                téléphoniques et les Tchats sont enregistrés à des fins
                probatoire et de contrôle de qualité. Conformément à la
                réglementation applicable, vous disposez d'un droit d'accès, de
                rectification, d'effacement, d'opposition pour motifs légitimes,
                de limitation du traitement, sous réserve de justifier de votre
                identité. Les modalités d'exercice de ces droits sont décrites
                dans le courrier électronique que vous recevrez dans le cadre de
                votre demande d'ouverture de compte. Vous avez également le
                droit d'introduire une réclamation auprès de l'autorité du
                marché.
              </p>

              <p>© BMCE CAPITAL 2022</p>
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>
</div>
