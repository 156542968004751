import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { MainPageModule } from '../app/main-page/main-page.module';
import { LandingPageComponent } from './component/landing-page/landing-page.component';
import { APP_BASE_HREF } from '@angular/common';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InformationsGeneralesComponent } from './component/informations-generales/informations-generales.component';


import { MatDialogModule } from '@angular/material/dialog';

@NgModule({
  declarations: [
    AppComponent,
    LandingPageComponent,
    InformationsGeneralesComponent,
    
  ],
  imports: [
    // MainPageModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatDialogModule
  ],
 // schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
 // entryComponents: [LivenessModalComponent],
  providers: [
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    // {provide: APP_BASE_HREF, useValue: 'onbk'}
    
  ],
  bootstrap: [AppComponent],
 // exports :[LivenessModalComponent]
})
export class AppModule {}
