

export const QuizStaticVariable = Object.freeze({
  COUNTRIES: [
    {text: 'Maroc', value: '146'},
    {text: 'France', value: '67'},
    {text: 'Afghanistan', value: '1'},
    {text: 'Afrique du Sud', value: '2'},
    {text: 'Albanie', value: '3'},
    {text: 'Algérie', value: '4'},
    {text: 'Allemagne', value: '5'},
    {text: 'Andorre', value: '6'},
    {text: 'Angola', value: '7'},
    {text: 'Anguilla', value: '8'},
    {text: 'Antarctique', value: '9'},
    {text: 'Antigua-et-Barbuda', value: '10'},
    {text: 'Antilles néerlandaises', value: '11'},
    {text: 'Arabie Saoudite', value: '12'},
    {text: 'Argentine', value: '13'},
    {text: 'Arménie', value: '14'},
    {text: 'Aruba', value: '15'},
    {text: 'Australie', value: '16'},
    {text: 'Autriche', value: '17'},
    {text: 'Azerbaïdjan', value: '18'},
    {text: 'Bahamas', value: '19'},
    {text: 'Bahreïn', value: '20'},
    {text: 'Bangladesh', value: '21'},
    {text: 'Barbades', value: '22'},
    {text: 'Belgique', value: '23'},
    {text: 'Belize', value: '24'},
    {text: 'Bénin', value: '25'},
    {text: 'Bermudes', value: '26'},
    {text: 'Bhoutan', value: '27'},
    {text: 'Biélorussie', value: '28'},
    {text: 'Bolivie', value: '29'},
    {text: 'Bosnie-Herzégovine', value: '30'},
    {text: 'Botswana', value: '31'},
    {text: 'Brésil', value: '32'},
    {text: 'Brunei', value: '33'},
    {text: 'Bulgarie', value: '34'},
    {text: 'Burkina Faso', value: '35'},
    {text: 'Burundi', value: '36'},
    {text: 'Cambodge', value: '37'},
    {text: 'Cameroun', value: '38'},
    {text: 'Canada', value: '39'},
    {text: 'Cap vert', value: '40'},
    {text: 'Chili', value: '41'},
    {text: 'Chine', value: '42'},
    {text: 'Chypre', value: '43'},
    {text: 'Colombie', value: '44'},
    {text: 'Comores', value: '45'},
    {text: 'Corée du Nord', value: '46'},
    {text: 'Corée du Sud', value: '47'},
    {text: 'Costa Rica', value: '48'},
    {text: 'Côte-d\'Ivoire', value: '49'},
    {text: 'Croatie', value: '50'},
    {text: 'Cuba', value: '51'},
    {text: 'Curaçao', value: '52'},
    {text: 'Danemark', value: '53'},
    {text: 'Djibouti', value: '54'},
    {text: 'Dominique', value: '55'},
    {text: 'Égypte', value: '56'},
    {text: 'Émirats Arabes Unis', value: '57'},
    {text: 'Équateur', value: '58'},
    {text: 'Érythrée', value: '59'},
    {text: 'Espagne', value: '60'},
    {text: 'Estonie', value: '61'},
    {text: 'État de Géorgie', value: '62'},
    {text: 'États-Unis (USA)', value: '63'},
    {text: 'Éthiopie', value: '64'},
    {text: 'Fiji', value: '65'},
    {text: 'Finlande', value: '66'},
    {text: 'Gabon', value: '68'},
    {text: 'Gambie', value: '69'},
    {text: 'Géorgie du Sud et les îles Sandwich', value: '70'},
    {text: 'Ghana', value: '71'},
    {text: 'Grèce', value: '72'},
    {text: 'Grenade', value: '73'},
    {text: 'Gibraltar', value: '74'},
    {text: 'Groenland', value: '75'},
    {text: 'Guadeloupe', value: '76'},
    {text: 'Guam', value: '77'},
    {text: 'Guatemala', value: '78'},
    {text: 'Guernesey', value: '79'},
    {text: 'Guinée', value: '80'},
    {text: 'Guinée équatoriale', value: '81'},
    {text: 'Guinée-Bissau', value: '82'},
    {text: 'Guyane', value: '83'},
    {text: 'Guyane française', value: '84'},
    {text: 'Haïti', value: '85'},
    {text: 'Honduras', value: '86'},
    {text: 'Hong Kong', value: '87'},
    {text: 'Hongrie', value: '88'},
    {text: 'Île Bouvet', value: '89'},
    {text: 'Île de Man', value: '90'},
    {text: 'Île Maurice', value: '91'},
    {text: 'Île Norfolk', value: '92'},
    {text: 'Îles Aland', value: '93'},
    {text: 'Îles Caïmans', value: '94'},
    {text: 'Îles Christmas', value: '95'},
    {text: 'Îles Cocos', value: '96'},
    {text: 'Îles Cook', value: '97'},
    {text: 'Îles Falkland', value: '98'},
    {text: 'Îles Féroé', value: '99'},
    {text: 'Îles Heard et îles MacDonald', value: '100'},
    {text: 'Îles Mariannes du Nord', value: '101'},
    {text: 'Îles Marshall', value: '102'},
    {text: 'Îles Mineures éloignées des États-Unis', value: '103'},
    {text: 'Îles Pitcairn', value: '104'},
    {text: 'Îles Salomon', value: '105'},
    {text: 'Îles Turques et Caïques', value: '106'},
    {text: 'Îles Vierges britanniques', value: '107'},
    {text: 'Îles Vierges des États-Unis', value: '108'},
    {text: 'Inde', value: '109'},
    {text: 'Indonésie', value: '110'},
    {text: 'Irak', value: '111'},
    {text: 'Iran', value: '112'},
    {text: 'Irlande', value: '113'},
    {text: 'Islande', value: '114'},
    {text: 'Israël', value: '115'},
    {text: 'Italie', value: '116'},
    {text: 'Jamaïque', value: '117'},
    {text: 'Japon', value: '118'},
    {text: 'Jersey', value: '119'},
    {text: 'Jordanie', value: '120'},
    {text: 'Kazakhstan', value: '121'},
    {text: 'Kenya', value: '122'},
    {text: 'Kirghizistan', value: '123'},
    {text: 'Kiribati', value: '124'},
    {text: 'Kosovo', value: '125'},
    {text: 'Koweït', value: '126'},
    {text: 'La Réunion', value: '127'},
    {text: 'Laos', value: '128'},
    {text: 'Le Salvador', value: '129'},
    {text: 'Lesotho', value: '130'},
    {text: 'Lettonie', value: '131'},
    {text: 'Liban', value: '132'},
    {text: 'Libéria', value: '133'},
    {text: 'Libye', value: '134'},
    {text: 'Liechtenstein', value: '135'},
    {text: 'Lituanie', value: '136'},
    {text: 'Luxembourg', value: '137'},
    {text: 'Macao', value: '138'},
    {text: 'Macédoine', value: '139'},
    {text: 'Madagascar', value: '140'},
    {text: 'Malawi', value: '141'},
    {text: 'Malaysie', value: '142'},
    {text: 'Maldives', value: '143'},
    {text: 'Mali', value: '144'},
    {text: 'Malte', value: '145'},
    {text: 'Martinique', value: '147'},
    {text: 'Mauritanie', value: '148'},
    {text: 'Mayotte', value: '149'},
    {text: 'Mexique', value: '150'},
    {text: 'Micronésie', value: '151'},
    {text: 'Moldavie', value: '152'},
    {text: 'Monaco', value: '153'},
    {text: 'Mongolie', value: '154'},
    {text: 'Montenegro', value: '155'},
    {text: 'Monténégro', value: '156'},
    {text: 'Montserrat', value: '157'},
    {text: 'Mozambique', value: '158'},
    {text: 'Myanmar', value: '159'},
    {text: 'Namibie', value: '160'},
    {text: 'Nauru', value: '161'},
    {text: 'Népal', value: '162'},
    {text: 'Nicaragua', value: '163'},
    {text: 'Niger', value: '164'},
    {text: 'Nigéria', value: '165'},
    {text: 'Niue', value: '166'},
    {text: 'Norvège', value: '167'},
    {text: 'Nouvelle Zélande', value: '168'},
    {text: 'Nouvelle-Calédonie', value: '169'},
    {text: 'Oman', value: '170'},
    {text: 'Ouganda', value: '171'},
    {text: 'Ouzbékistan', value: '172'},
    {text: 'Pakistan', value: '173'},
    {text: 'Palaos', value: '174'},
    {text: 'Panama', value: '175'},
    {text: 'Papouasie-Nouvelle-Guinée', value: '176'},
    {text: 'Paraguay', value: '177'},
    {text: 'Pays-Bas', value: '178'},
    {text: 'Pérou', value: '179'},
    {text: 'Philippines', value: '180'},
    {text: 'Pologne', value: '181'},
    {text: 'Polynésie française', value: '182'},
    {text: 'Portugal', value: '183'},
    {text: 'Puerto Rico', value: '184'},
    {text: 'Qatar', value: '185'},
    {text: 'République centrafricaine', value: '186'},
    {text: 'République démocratique du Congo', value: '187'},
    {text: 'République Dominicaine', value: '188'},
    {text: 'République du Congo', value: '189'},
    {text: 'République Tchèque', value: '190'},
    {text: 'Roumanie', value: '191'},
    {text: 'Royaume-Uni', value: '192'},
    {text: 'Russie', value: '193'},
    {text: 'Rwanda', value: '194'},
    {text: 'Saba, Saint-Eustache et Bonaire', value: '195'},
    {text: 'Saint Barthélemy', value: '196'},
    {text: 'Saint Pierre et Miquelon', value: '197'},
    {text: 'Sainte-Hélène', value: '198'},
    {text: 'Sainte-Lucie', value: '199'},
    {text: 'Saint-Kitts-et-Nevis', value: '200'},
    {text: 'Saint-Martin', value: '201'},
    {text: 'Saint-Vincent-et-les-Grenadines', value: '202'},
    {text: 'Samoa', value: '203'},
    {text: 'Samoa américaines', value: '204'},
    {text: 'San Marin', value: '205'},
    {text: 'Sao Tomé-et-Principe', value: '206'},
    {text: 'Sénégal', value: '207'},
    {text: 'Serbie', value: '208'},
    {text: 'Seychelles', value: '209'},
    {text: 'Sierra Leone', value: '210'},
    {text: 'Singapour', value: '211'},
    {text: 'Slovaquie', value: '212'},
    {text: 'Slovénie', value: '213'},
    {text: 'Somalie', value: '214'},
    {text: 'Soudan', value: '215'},
    {text: 'Soudan du Sud', value: '216'},
    {text: 'Sri Lanka', value: '217'},
    {text: 'Suède', value: '218'},
    {text: 'Suisse', value: '219'},
    {text: 'Surinam', value: '220'},
    {text: 'Svalbard et Jan Mayen', value: '221'},
    {text: 'Swaziland', value: '222'},
    {text: 'Syrie', value: '223'},
    {text: 'Taiwan', value: '224'},
    {text: 'Tajikistan', value: '225'},
    {text: 'Tanzanie', value: '226'},
    {text: 'Tchad', value: '227'},
    {text: 'Terres australes et antarctiques françaises', value: '228'},
    {text: 'Territoire Britannique de l\'Océan Indien', value: '229'},
    {text: 'Territoire Palestinien', value: '230'},
    {text: 'Thaïlande', value: '231'},
    {text: 'Timor oriental', value: '232'},
    {text: 'Togo', value: '233'},
    {text: 'Tokelau', value: '234'},
    {text: 'Tonga', value: '235'},
    {text: 'Trinité et Tobago', value: '236'},
    {text: 'Tunisie', value: '237'},
    {text: 'Turkménistan', value: '238'},
    {text: 'Turquie', value: '239'},
    {text: 'Tuvalu', value: '240'},
    {text: 'Ukraine', value: '241'},
    {text: 'Uruguay', value: '242'},
    {text: 'Vanuatu', value: '243'},
    {text: 'Vatican', value: '244'},
    {text: 'Venezuela', value: '245'},
    {text: 'Vietnam', value: '246'},
    {text: 'Wallis et Futuna', value: '247'},
    {text: 'Yemen', value: '248'},
    {text: 'Zambie', value: '249'},
    {text: 'Zimbabwe', value: '250'},
    {text: 'NA', value: '251'},
  ],
  CANAL_RELATION: [
    {text: 'Entrée en relation Directe', value: '1'},
    {text: 'Entrée en relation Indirecte (par le bias d\'intermédiaires, apporteurs d\'affaires, réseau de distribution)', value: '2'}
  ],
  CLIENT_TYPE: [
    {text: 'Marocain Résident', value: '1'},
    {text: 'Marocain Non Résident', value: '2'},
    {text: 'Etranger Résident', value: '3'},
    {text: 'Etranger Non Résident', value: '4'},
  ],
  MARITAL_STATUS: [
    {text: 'Célibataire', value: '1'},
    {text: 'Marié( e )', value: '2'},
    {text: 'Veuf(ve)', value: '3'},
    {text: 'divorcé(e)', value: '4'}
  ],
  JOB: [
    {text: 'Adoul', value: '1'},
    {text: 'Agent de joueur', value: '2'},
    {text: 'Agent de sécurité ', value: '3'},
    {text: 'Agriculteur', value: '4'},
    {text: 'Ambassadeur', value: '5'},
    {text: 'Antiquaire', value: '6'},
    {text: 'Architecte  ', value: '7'},
    {text: 'Archiviste ', value: '8'},
    {text: 'Artisan', value: '9'},
    {text: 'Assistante de direction', value: '10'},
    {text: 'Assureur ', value: '11'},
    {text: 'Auditeur interne', value: '12'},
    {text: 'Autres activités', value: '13'},
    {text: 'Autres professions libérales', value: '14'},
    {text: 'Avocat', value: '15'},
    {text: 'Boucher', value: '16'},
    {text: 'Boulanger, Patissier', value: '17'},
    {text: 'Cadre d\'assurances ', value: '18'},
    {text: 'Cadre de banque ', value: '19'},
    {text: 'Chef d\'entreprise', value: '20'},
    {text: 'Chef religieux', value: '21'},
    {text: 'Coiffeur', value: '22'},
    {text: 'Commerçant ', value: '23'},
    {text: 'Commercial', value: '24'},
    {text: 'Comptable', value: '25'},
    {text: 'Conducteur', value: '26'},
    {text: 'Conseilleur clientèle ', value: '27'},
    {text: 'consultant', value: '28'},
    {text: 'contrôleur de gestion', value: '29'},
    {text: 'Coordinateur de projet ', value: '30'},
    {text: 'Cuisinier', value: '31'},
    {text: 'délégué médical', value: '32'},
    {text: 'Diplomate ', value: '33'},
    {text: 'Dirigeant', value: '34'},
    {text: 'Douanier', value: '35'},
    {text: 'Electricien', value: '36'},
    {text: 'Employé', value: '37'},
    {text: 'Enseignant ', value: '38'},
    {text: 'Entepreneur  BTP', value: '39'},
    {text: 'Entrepreneur', value: '40'},
    {text: 'Esthéticienne', value: '41'},
    {text: 'Etudiant', value: '42'},
    {text: 'Expert comptable', value: '43'},
    {text: 'femme au foyer', value: '44'},
    {text: 'Fonctionnaire ', value: '45'},
    {text: 'Formateur', value: '46'},
    {text: 'Greffier ', value: '47'},
    {text: 'Guide touristique ', value: '48'},
    {text: 'Hôtesse de l\'air', value: '49'},
    {text: 'Huissier de Justice', value: '50'},
    {text: 'Infirmière', value: '51'},
    {text: 'Informaticien', value: '52'},
    {text: 'Ingénieur', value: '53'},
    {text: 'Journaliste', value: '54'},
    {text: 'Juriste ', value: '55'},
    {text: 'Magistrat', value: '56'},
    {text: 'médecin', value: '57'},
    {text: 'Menuisier', value: '58'},
    {text: 'Militaire  /officier / policier ', value: '59'},
    {text: 'Notaire', value: '60'},
    {text: 'Opticien', value: '61'},
    {text: 'Ouvrier', value: '62'},
    {text: 'Pharmacien', value: '63'},
    {text: 'Pilote au commandant de bord', value: '64'},
    {text: 'pompiste gérant de station de service', value: '65'},
    {text: 'Prêtre', value: 66},
    {text: 'Responsable administratif et financier', value: '67'},
    {text: 'Restaurateur ', value: '68'},
    {text: 'Retraité', value: '69'},
    {text: 'Sans emploi', value: '70'},
    {text: 'Secrétaire', value: '71'},
    {text: 'sportif professionnel', value: '72'},
    {text: 'Tailleur', value: '73'},
    {text: 'Traiteur', value: '74'},
    {text: 'Transitaire', value: '75'},
    {text: 'transporteur routier', value: '76'},
    {text: 'Vétérinaire', value: '77'},
  ],
  ACTIVITY_TYPE: [
    {text: 'Activités de services diverses', value: '1'},
    {text: 'Aéronautique', value: '2'},
    {text: 'Agriculture, pêche', value: '3'},
    {text: 'Agro-alimentaire et Boissons', value: '4'},
    {text: 'Architecture', value: '5'},
    {text: 'Associations et Groupements Professionnels', value: '6'},
    {text: 'Assurances', value: '7'},
    {text: 'Automobile et équipements', value: '8'},
    {text: 'Autres', value: '9'},
    {text: 'Autres Industries', value: '10'},
    {text: 'Autres Services financiers', value: '11'},
    {text: 'Banques et sociétés de crédit', value: '12'},
    {text: 'Bâtiment et matériaux de construction', value: '13'},
    {text: 'Biens de consommation', value: '14'},
    {text: 'Bijoux  et joaillerie ', value: '15'},
    {text: 'Caisses de Retraite', value: '16'},
    {text: 'Casino', value: '17'},
    {text: 'Commerce', value: '18'},
    {text: 'Communication, Médias et Imprimeries', value: '19'},
    {text: 'Distribution', value: '20'},
    {text: 'Etudes, Formation et Conseil', value: '21'},
    {text: 'Expertise comptable', value: '22'},
    {text: 'Fondations', value: '23'},
    {text: 'Holding', value: '24'},
    {text: 'Hôtel et restauration ', value: '25'},
    {text: 'Hydraulique et distribution d\'eau', value: '26'},
    {text: 'Hydrocarbure et production du gaz', value: '27'},
    {text: 'Immobilier', value: '28'},
    {text: 'Industries agricoles et alimentaires', value: '29'},
    {text: 'Industries chimiques', value: '30'},
    {text: 'Industries des matériaux de construction de céramique et de verrre', value: '31'},
    {text: 'Industries du bois, du papier et du plastique', value: '32'},
    {text: 'Industries du textile, de l\'habillement et des cuirs', value: '33'},
    {text: 'Industries mécaniques et électriques', value: '34'},
    {text: 'Informatique, Télécommunication et Multimédia', value: '35'},
    {text: 'Institutions d\'enseignement et de recherche', value: '36'},
    {text: 'Juridique/Droit', value: '37'},
    {text: 'Leasing', value: '38'},
    {text: 'Matériaux de base', value: '39'},
    {text: 'Mines et carrières', value: '40'},
    {text: 'Ministères et administrations publiques', value: '41'},
    {text: 'Missions diplomatiques et consulaires', value: '42'},
    {text: 'Œuvres d\'art / objet de luxe ', value: '43'},
    {text: 'Organisation à caractère religieux', value: '44'},
    {text: 'Organismes de Prévoyance', value: '45'},
    {text: 'Pharmaceutique', value: '46'},
    {text: 'Production et distribution d\'électricité et du gaz', value: '47'},
    {text: 'Santé, Soins de santé et assistance sociale', value: '48'},
    {text: 'Services aux consommateurs', value: '49'},
    {text: 'Sté de Développement Local (SDL)', value: '50'},
    {text: 'Tourisme, Voyages et Loisirs', value: '51'},
    {text: 'Transport et télécommunications', value: '52'}
  ],
  ID_TYPE: [
    {text: 'Carte d\'identité', value: '1'},
    {text: 'Passeport', value: '2'},
    {text: 'Carte d\'immatriculation', value: '3'},
    {text: 'Carte de séjour', value: '4'}
  ],
  REVENUES: [
    {text: 'Moins de 100 000 DHs', value: '1'},
    {text: 'Entre 100 000 et 500 000 DHs', value: '2'},
    {text: 'Entre 500 000 et 1 000 000 DHs', value: '3'},
    {text: 'Plus de 1 000 000 DHs', value: '4'}
  ],
  RELATION_PURPOSE: [
    {text: 'Assurer des revenus récurrents', value: '1'},
    {text: 'Réaliser revenus CT/MT', value: '5'},
    {text: 'Diversifier un patrimoine', value: '3'},
    {text: 'Constituer un plan épargne / retraite', value: '2'},
    {text: 'Préparer d\'une succession', value: '4'},
  ],
  TRANSACTION: [
    {text: 'Action', value: '1'},
    {text: 'Obligation', value: '2'},
    {text: 'OPCVM', value: '3'},
    {text: 'Produits structurés', value: '4'}
  ],
  GENDER: [
    {text: 'Féminin', value: '1'},
    {text: 'Masculin', value: '2'},
  ],
  OUI_NON: [
    {
      text: 'Oui',
      value: '1',
    },
    {
      text: 'Non',
      value: '2',
    }],
  OUI_NON_WITH_TEXT: [
    {
      text: 'Oui',
      value: 'other',
    },
    {
      text: 'Non',
      value: '2',
    }],
  REVENUES_SOURCES: [
    {text: 'Salaire', value: '1'},
    {text: 'Epargne personnelle', value: '2'},
    {text: 'Pension alimentaire', value: '3'},
    {text: 'Revenus mobiliers', value: '4'},
    {text: 'Vente de bien', value: '5'},
    {text: 'Héritage', value: '6'},
    {text: 'Rentes', value: '7'},
    {text: 'Revenu de propriété immobilière', value: '8'},
    {text: 'Retraites', value: '9'},
    {text: 'Pension d\'invalidité', value: '10'},
    {text: 'Produits bancaires', value: '11'},
    {text: 'Contrats d\'assurances', value: '12'},
    {text: 'Revenu professionnel', value: '13'},
    {text: 'Donation', value: '14'},
    {text: 'Autres', value: 'other'}
  ],
  PATRMONY_DISTRIBUTION: [
    {
      value: '1',
      text: 'Produits bancaires traditionnels (à revenus fixes)',
    },
    {
      value: '2',
      text: 'Biens immobiliers',
    },
    {
      value: '3',
      text: 'Contrats d’assurances',
    },
    {
      value: '4',
      text: 'Portefeuille en valeur mobilière',
    },
    {
      value: '5',
      text: 'Dépôt compte bancaire',

    }, {text: 'Autres', value: 'other'}
  ],
  INVESTEMENT_HORIZON: [{
    value: '1',
    text: 'Court terme : moins d’un an'
  },
    {
      value: '2',
      text: 'Moyen terme : entre 1 an et 3 ans'
    },
    {
      value: '3',
      text: 'Long terme : plus de 3 ans'
    }
  ],
  INTERESTED_SECTORS: [{
    value: '1',
    text: 'Immobilier '
  },
    {
      value: '2',
      text: 'Télécommunication '
    },
    {
      value: '3',
      text: 'Banque '
    },
    {
      value: '4',
      text: 'Assurance '
    },
    {
      value: '5',
      text: 'Agroalimentaire  '
    },
    {
      value: '6',
      text: 'Matériels logiciels et services informations'
    },
    {
      value: '7',
      text: 'BTP'
    },
    {
      value: '8',
      text: 'Mines'
    },
    {
      value: '9',
      text: 'Transport'
    },
    {
      value: '10',
      text: 'Energie'
    },
    {
      value: '11',
      text: 'Chimie'
    },
    {
      value: '12',
      text: 'Distribution'
    },
    {
      value: '13',
      text: 'Autres'
    },

  ],
  PROFIT_POLITICS: [{
    value: '1',
    text: 'Un réinvestissement des profits réalisés sur le marché'
  },
    {
      value: '2',
      text: 'Une orientation des profits vers d’autres placements, en l’occurrence les placements bancaires'
    },
    {
      value: '3',
      text: 'Une prise de bénéfices ferme'
    },
    {text: 'Autre', value: 'other'}
  ],
  INVESTOR_PROFIL: [{
    value: '1',
    text: 'Prudent : Risque minimal / potentiel de rentabilité faible'
  },
    {
      value: '2',
      text: 'Equilibré: Risque modéré/ potentiel de rentabilité moyen'
    },
    {
      value: '3',
      text: 'Dynamique: Risque élevé / potentiel de rentabilité élevé'
    },
    {
      value: '4',
      text: 'Offensif: Risque très élevé/objectif de performance maximale'
    }],
  INVESTOR_KNOWLEDGE: [{
    value: '1',
    text: 'Excellent '
  },
    {
      value: '2',
      text: 'Modéré '
    },
    {
      value: '3',
      text: 'faible'
    },
    {
      value: '4',
      text: 'Exige un accompagnement en la matière'
    }],
  INVESTOR_INTERVENTIONS: [
    {
      value: '1',
      text: 'En une fois'
    },
    {
      value: '2',
      text: 'Selon les opportunités du marché'
    },
    {
      value: '3',
      text: 'En plusieurs interventions périodiques'
    },
    {text: 'Autre', value: 'other'}
  ],
  PORTFOLIO_ROTATION: [{
    value: '1',
    text: 'Moins d’une fois'
  },
    {
      value: '2',
      text: '1 à 2 fois'
    },
    {
      value: '3',
      text: '2 fois et plus'
    }],
  MONEY_LOST: [{
    value: '1',
    text: 'Oui'
  },
    {
      value: '2',
      text: 'Non'
    }],
  EFFECTIVE_BENEFICIARY: [{
    value: '1',
    text: 'Oui'
  },
    {
      value: 'other',
      text: 'Autres (A préciser)'
    }],
  CLIENT_JOB_TYPE: [{
    value: '1',
    text: 'Clients commerçants'
  },
    {
      value: '2',
      text: 'Clients auto-entrepreneurs'
    },
    {
      value: '3',
      text: 'Clients particuliers'
    }],
  OFFRED_SERVICES: [
    {
      value: '1',
      text: 'Service Intermédiation'
    },
    {
      value: '2',
      text: 'Service Intermédiation & Dépositaire'
    },
    {
      value: '3',
      text: 'Service Intermédiation, Dépositaire & Bourse en Ligne'
    },
  ],
  PPE_RELATIVE_TYPE: [
    {text: 'Cercle familial', value: '1'},
    {text: 'Cercle d\'influence', value: '2'}
  ],
  DEPOSITAIRE: [
    {text: 'AUCUN', value: '0'},
    {text: 'AL BARID BANK', value: '1'},
    {text: 'AL WASSIT', value: '2'},
    {text: 'ARAB BANK MA', value: '3'},
    {text: 'ARTBOURSE', value: '4'},
    {text: 'ATLAS CAPITAL BOURSE', value: '5'},
    {text: 'ATTIJARI  INTERMEDIATION', value: '6'},
    {text: 'ATTIJARIWAFA BANK', value: '7'},
    {text: 'BANK OF AFRICA', value: '8'},
    {text: 'BANQUE POPULAIRE', value: '9'},
    {text: 'BMCE CAPITAL BOURSE', value: '10'},
    {text: 'BMCI ', value: '11'},
    {text: 'CAISSE DE DEPOT ET DE GESTION', value: '12'},
    {text: 'CDG CAPITAL', value: '13'},
    {text: 'CDG CAPITAL BOURSE', value: '14'},
    {text: 'CFG BANK', value: '15'},
    {text: 'CIH BANK', value: '16'},
    {text: 'CITIBANK', value: '17'},
    {text: 'CREDIT AGRICOLE DU MAROC', value: '18'},
    {text: 'CREDIT DU MAROC', value: '19'},
    {text: 'CREDIT DU MAROC CAPITAL', value: '20'},
    {text: 'MEDIAFINANCE', value: '21'},
    {text: 'MENA. C.P.', value: '22'},
    {text: 'MSIN', value: '23'},
    {text: 'SOCIETE GENERALE MAROC', value: '24'},
    {text: 'UPLINE', value: '25'},
    {text: 'VALORIS SECURITIES', value: '26'}
  ],

  EV_BANK: [
    {text: 'Attijariwafa Bank', value: '1'},
    {text: 'Bank Al Maghreb', value: '2'},
    {text: 'Bank Of Africa', value: '3'},
    {text: 'Banque populaire', value: '4'},
    {text: 'BMCI', value: '5'},
    {text: 'CDG', value: '6'},
    {text: 'CFG Bank', value: '7'},
    {text: 'CIH', value: '8'},
    {text: 'Citibank', value: '9'},
    {text: 'Crédit Agricole', value: '10'},
    {text: 'Crédit du Maroc', value: '11'},
    {text: 'Société Générale', value: '12'},
    {text: 'Autres', value: 'other'}

  ],


/*
  FRENCH: Validators.compose([Validators.required, Validators.pattern('[A-Za-zàâçéèêëîïôûùüÿñæœ .-]*')]),
  FRENCH_WITH_NUMBERS: Validators.compose([Validators.required, Validators.pattern('[0-9A-Za-zàâçéèêëîïôûùüÿñæœ .-]*')]),
  RIB: Validators.compose([Validators.required, Validators.pattern('[0-9]*'), Validators.maxLength(24), Validators.minLength(24)]),
*/

});


export const QuizVariable = {


  ID_TYPE_GROUPS: [
    {
      countryName: 'Maroc',
      idType: [
        {value: 'marocCIN', viewValue: 'CIN', enable: 'false'},
        {value: 'marocP', viewValue: 'Passeport', enable: 'false'},
      ]
    },
    {
      countryName: 'France',
      idType: [
        {value: 'franceCIN', viewValue: 'CIN', enable: 'false'},
        {value: 'franceP', viewValue: 'Passeport', enable: 'false'},
      ]
    },
    {
      countryName: 'États-Unis',
      idType: [
        {value: 'usaP', viewValue: 'Passeport', enable: 'false'},
        {value: 'usaPC', viewValue: 'Passeport Card', enable: 'false'},
      ]
    },
    {
      countryName: 'Autre',
      idType: [
        {value: 'other', viewValue: 'Autre', enable: 'false'}
      ]
    }

  ],

  DATA_TO_SEND: {
    'idCardUploaded': false,
    'userToken': '',
    'dataStatut': 'first_submit',
    'noParent': {
      'item': [],
      'data': ['']
    },
    'accountNumber': {
      'item': [],
      'data': ['']
    },
    'statusClient': '',

    'NewRelationship': {
      'item': [],
      'data': ['']
    },
    'Update': false,
    'offredServices': {
      'item': [],
      'data': ['']
    },
    'ClientN': {
      'item': [],
      'data': ['']
    },
    'channelRelation': {
      'item': [],
      'data': ['']
    },
    'typologieClient': {
      'item': [],
      'data': ['']
    },
    'numTiers': {
      'item': [],
      'data': ['']
    },
    'name': {
      'item': [],
      'data': ['']
    },
    'fName': {
      'item': [],
      'data': ['']
    },
    'gender': {
      'item': [],
      'data': ['']
    },
    'maritalStatus': {
      'item': [],
      'data': ['']
    },
    'birthDate': {
      'item': [],
      'data': ['']
    },
    'birthCity': {
      'item': [],
      'data': ['']
    },
    'birthCountry': {
      'item': [],
      'data': ['']
    },
    'nationality': {
      'item': [],
      'data': ['']
    },
    'statusMartial': {
      'item': [],
      'data': ['']
    },
    'secNationality': {
      'item': [],
      'data': ['']
    },
    'checkCorrespLegalAdress': {
      'item': [],
      'data': ['']
    },
    'idCountry': {
      'item': [],
      'data': ['']
    },
    'idValidDate1': {
      'item': [],
      'data': ['']
    },
    'legalRepresentativeName': {
      'item': [],
      'data': [
        ''
      ]
    },
    'legalRepresentativeQuality': {
      'item': [],
      'data': [
        ''
      ]
    },
    'idValidDate2': {
      'item': [],
      'data': ['']
    },
    'phoneNum': {
      'item': [],
      'data': ['']
    },
    'phoneCountry': {
      'item': [],
      'data': ['']
    },
    'FaxNum': {
      'item': [],
      'data': ['']
    },
    'email': {
      'item': [],
      'data': ['']
    },
    'fatherName': {
      'item': [],
      'data': ['']
    },
    'fatherFname': {
      'item': [],
      'data': ['']
    },
    'motherName': {
      'item': [],
      'data': ['']
    },
    'motherFname': {
      'item': [],
      'data': ['']
    },
    'currentAdress': {
      'item': [],
      'data': ['']
    },
    'taxAdress': {
      'item': [],
      'data': ['']
    },
    'taxZipCode': {
      'item': [],
      'data': ['']
    },
    'clientPhone': {
      'item': [],
      'data': ['']
    },
    'countryResidence': {
      'item': [],
      'data': ['']
    },
    'cityResidence': {
      'item': [],
      'data': ['']
    },
    'zipCodeResidence': {
      'item': [],
      'data': ['']
    },
    'justifResidence': {
      'item': [],
      'data': ['']
    },
    'otherResidence': {
      'item': [],
      'data': ['']
    },
    'correspAdress': {
      'item': [],
      'data': ['']
    },
    'correspZipCode': {
      'item': [],
      'data': ['']
    },
    'correspCity': {
      'item': [],
      'data': ['']
    },
    'correspCountry': {
      'item': [],
      'data': ['']
    },
    'correspBPAdress': {
      'item': [],
      'data': ['']
    },
    'countryTaxResidence': {
      'item': [],
      'data': ['']
    },
    'countryNative': {
      'item': [],
      'data': ['']
    },
    'job': {
      'item': [],
      'data': ['']
    },
    'prevJobCity': {
      'item': [],
      'data': ['']
    },
    'prevJobCountry': {
      'item': [],
      'data': ['']
    },
    'contractActivity': {
      'item': [],
      'data': ['']
    },
    'contractIncome': {
      'item': [],
      'data': ['']
    },
    'contractOriginIncome': {
      'item': [],
      'data': ['']
    },
    'employer': {
      'item': [],
      'data': ['']
    },
    'employerAdress': {
      'item': [],
      'data': ['']
    },
    'employerCountry': {
      'item': [],
      'data': ['']
    },
    'employerZibCode': {
      'item': [],
      'data': ['']
    },
    'employerCity': {
      'item': [],
      'data': ['']
    },
    'activitySector': {
      'item': [],
      'data': ['']
    },
    'startingDateForProfessionals': {
      'item': [],
      'data': ['']
    },
    'seniority': {
      'item': [],
      'data': ['']
    },
    'geographicPeremeter': {
      'item': [],
      'data': ['']
    },
    'otherActivities': {
      'item': [],
      'data': ['']
    },
    'annualIncome': {
      'item': [],
      'data': ['']
    },
    'purpose': {
      'item': [],
      'data': [''],
      'other': ''
    },
    'countryFondsIn': {
      'item': [],
      'data': ['']
    },
    'countryFondsOut': {
      'item': [],
      'data': ['']
    },
    'depositary': {
      'item': [],
      'data': ['']
    },
    'depositionAtBKB': {
      'item': [],
      'data': ['']
    },
    'AccountNumber': {
      'item': [],
      'data': ['']
    },
    'clientType': {
      'item': [],
      'data': ['']
    },
    'clientJobType': {
      'item': [],
      'data': ['']
    },
    'codeID': {
      'item': [],
      'data': ['']
    },
    'typeID': {
      'item': [],
      'data': ['']
    },
    'autority': {
      'item': [],
      'data': ['']
    },
    'emissionDate': {
      'item': [],
      'data': ['']
    },
    'expirationDate': {
      'item': [],
      'data': ['']
    },
    'legalRepresentative': {
      'item': [],
      'data': ['']
    },
    'registrationCourt': {
      'item': [],
      'data': ['']
    },
    'NProfessionalTax': {
      'item': [],
      'data': ['']
    },
    'RNAE': {
      'item': [],
      'data': ['']
    },
    'RC': {
      'item': [],
      'data': ['']
    },
    'contractDenom': {
      'item': [],
      'data': ['']
    },
    'contractImmatCentre': {
      'item': [],
      'data': ['']
    },
    'contractNumPatente': {
      'item': [],
      'data': ['']
    },
    'RCPlaceDeliver': {
      'item': [],
      'data': ['']
    },
    'ICE': {
      'item': [],
      'data': ['']
    },
    'FATCANationality': {
      'item': [],
      'data': ['']
    },
    'FATCAOtherNationality': {
      'item': [],
      'data': ['']
    },
    'FATCAAdress': {
      'item': [],
      'data': ['']
    },
    'FATCAPhone': {
      'item': [],
      'data': ['']
    },
    'FATCAAttorney': {
      'item': [],
      'data': ['']
    },
    'FATCAMoneytransfer': {
      'item': [],
      'data': ['']
    },
    'FATCAAccountUSA': {
      'item': [],
      'data': ['']
    },
    'FATCAGreenCard': {
      'item': [],
      'data': ['']
    },
    'FRCStatut': {
      'item': [],
      'data': ['']
    },
    'FRCExtent': {
      'item': [],
      'data': ['']
    },
    'FRCName': {
      'item': [],
      'data': ['']
    },
    'FRCFname': {
      'item': [],
      'data': ['']
    },
    'FRCRelation': {
      'item': [],
      'data': ['']
    },
    'FRCNationality': {
      'item': [],
      'data': ['']
    },
    'FRCResidence': {
      'item': [],
      'data': ['']
    },
    'FRCIdType': {
      'item': [],
      'data': ['']
    },
    'FRCIdCode': {
      'item': [],
      'data': ['']
    },
    'FRCDeliver': {
      'item': [],
      'data': ['']
    },
    'FRCDate': {
      'item': [],
      'data': ['']
    },
    'FRCValidity': {
      'item': [],
      'data': ['']
    },
    'FRCCode': {
      'item': [],
      'data': ['']
    },
    'PPEStatut': {
      'item': [],
      'data': ['']
    },
    'PPERelativeStatut': {
      'item': [],
      'data': ['']
    },
    'FATCAStatut': {
      'item': [],
      'data': ['']
    },
    'PPERelativeType': {
      'item': [],
      'data': ['']
    },
    'PPENationalityRelative': {
      'item': [],
      'data': ['']
    },
    'PPECountryResidence': {
      'item': [],
      'data': ['']
    },
    'PPEMandate': {
      'item': [],
      'data': ['']
    },
    'PPEEndDate': {
      'item': [],
      'data': ['']
    },
    'PPERelatives': {
      'item': [],
      'data': ['']
    },
    'PPEName': {
      'item': [],
      'data': ['']
    },
    'PPEFname': {
      'item': [],
      'data': ['']
    },
    'PPEBirthDate': {
      'item': [],
      'data': ['']
    },
    'PPEBirthPlace': {
      'item': [],
      'data': ['']
    },
    'PPEJob': {
      'item': [],
      'data': ['']
    },
    'PPEJobRelative': {
      'item': [],
      'data': ['']
    },
    'PPEJobCountry': {
      'item': [],
      'data': ['']
    },
    'PPEEndDateRelative': {
      'item': [],
      'data': ['']
    },
    'PPEJobCountryRelative': {
      'item': [],
      'data': ['']
    },
    'PPERelative': {
      'item': [],
      'data': ['']
    },
    'PPEIdEntity': {
      'item': [],
      'data': ['']
    },
    'PPERelativeNatureLink': {
      'item': [],
      'data': ['']
    },
    'PPEOtherInformation': {
      'item': [],
      'data': ['']
    },
    'PPEExpirationDate': {
      'item': [],
      'data': ['']
    },
    'PPEEmissionDate': {
      'item': [],
      'data': ['']
    },
    'PPEDeliveranceCountry': {
      'item': [],
      'data': ['']
    },
    'EVBank1': {
      'item': [],
      'data': ['']
    },
    'EVAgency1': {
      'item': [],
      'data': ['']
    },
    'EVCity1': {
      'item': [],
      'data': ['']
    },
    'EVRIB1': {
      'item': [],
      'data': ['']
    },
    'EVBank2': {
      'item': [],
      'data': ['']
    },
    'EVAgency2': {
      'item': [],
      'data': ['']
    },
    'EVCity2': {
      'item': [],
      'data': ['']
    },
    'EVRIB2': {
      'item': [],
      'data': ['']
    },
    'revenues': {
      'item': [],
      'data': ['']
    },
    'revenuesSource': {
      'item': [],
      'data': [''],
      'other': ''
    },
    'patrmonyDistribution': {
      'item': [],
      'data': [''],
      'other': ''
    },
    'investementHorizon': {
      'item': [],
      'data': [''],
      'other': ''
    },
    'interestedSectors': {
      'item': [],
      'data': [''],
      'other': ''
    },
    'transaction': {
      'item': [],
      'data': [''],
      'other': ''
    },
    'profitPolitics': {
      'item': [],
      'data': [''],
      'other': ''
    },
    'investorProfil': {
      'item': [],
      'data': ['']
    },
    'investorKnowledge': {
      'item': [],
      'data': ['']
    },
    'investorInterventions': {
      'item': [],
      'data': [''],
      'other': ''
    },
    'portfolioRotation': {
      'item': [],
      'data': ['']
    },
    'moneyLost': {
      'item': [],
      'data': ['']
    },
    'effectiveBeneficiary': {
      'item': [],
      'data': ['']
    },
    'Statement': {
      'item': [],
      'data': ['']
    },
    'MadeIn': {
      'item': [],
      'data': ['']
    },
    'MadeOn': {
      'item': [],
      'data': ['']
    }
  },

  DATA_TO_SEND_ATTORNY_1: {
    'idCardUploaded': false,
    'userToken': '',
    'statusClient': '',

    'NewRelationship': {
      'item': [],
      'data': ['']
    },
    'maritalStatus': {
      'item': [],
      'data': ['']
    },
    'offredServices': {
      'item': [],
      'data': ['']
    },

    'Update': false,
    'ClientN': {
      'item': [],
      'data': ['']
    },
    'channelRelation': {
      'item': [],
      'data': ['']
    },
    'typologieClient': {
      'item': [],
      'data': ['']
    },
    'numTiers': {
      'item': [],
      'data': ['']
    },
    'name': {
      'item': [],
      'data': ['']
    },
    'fName': {
      'item': [],
      'data': ['']
    },
    'gender': {
      'item': [],
      'data': ['']
    },
    'birthDate': {
      'item': [],
      'data': ['']
    },
    'birthCity': {
      'item': [],
      'data': ['']
    },
    'birthCountry': {
      'item': [],
      'data': ['']
    },
    'nationality': {
      'item': [],
      'data': ['']
    },
    'statusMartial': {
      'item': [],
      'data': ['']
    },
    'secNationality': {
      'item': [],
      'data': ['']
    },
    'checkCorrespLegalAdress': {
      'item': [],
      'data': ['']
    },
    'idCountry': {
      'item': [],
      'data': ['']
    },
    'idValidDate1': {
      'item': [],
      'data': ['']
    },
    'legalRepresentativeName': {
      'item': [],
      'data': [
        ''
      ]
    },
    'legalRepresentativeQuality': {
      'item': [],
      'data': [
        ''
      ]
    },
    'idValidDate2': {
      'item': [],
      'data': ['']
    },
    'phoneNum': {
      'item': [],
      'data': ['']
    },
    'phoneCountry': {
      'item': [],
      'data': ['']
    },
    'FaxNum': {
      'item': [],
      'data': ['']
    },
    'email': {
      'item': [],
      'data': ['']
    },
    'fatherName': {
      'item': [],
      'data': ['']
    },
    'fatherFname': {
      'item': [],
      'data': ['']
    },
    'motherName': {
      'item': [],
      'data': ['']
    },
    'motherFname': {
      'item': [],
      'data': ['']
    },
    'currentAdress': {
      'item': [],
      'data': ['']
    },
    'taxAdress': {
      'item': [],
      'data': ['']
    },
    'taxZipCode': {
      'item': [],
      'data': ['']
    },
    'clientPhone': {
      'item': [],
      'data': ['']
    },
    'countryResidence': {
      'item': [],
      'data': ['']
    },
    'cityResidence': {
      'item': [],
      'data': ['']
    },
    'zipCodeResidence': {
      'item': [],
      'data': ['']
    },
    'justifResidence': {
      'item': [],
      'data': ['']
    },
    'otherResidence': {
      'item': [],
      'data': ['']
    },
    'correspAdress': {
      'item': [],
      'data': ['']
    },
    'correspZipCode': {
      'item': [],
      'data': ['']
    },
    'correspCity': {
      'item': [],
      'data': ['']
    },
    'correspCountry': {
      'item': [],
      'data': ['']
    },
    'correspBPAdress': {
      'item': [],
      'data': ['']
    },
    'countryTaxResidence': {
      'item': [],
      'data': ['']
    },
    'countryNative': {
      'item': [],
      'data': ['']
    },
    'job': {
      'item': [],
      'data': ['']
    },
    'prevJobCity': {
      'item': [],
      'data': ['']
    },
    'prevJobCountry': {
      'item': [],
      'data': ['']
    },
    'contractActivity': {
      'item': [],
      'data': ['']
    },
    'contractIncome': {
      'item': [],
      'data': ['']
    },
    'contractOriginIncome': {
      'item': [],
      'data': ['']
    },
    'employer': {
      'item': [],
      'data': ['']
    },
    'employerAdress': {
      'item': [],
      'data': ['']
    },
    'employerZibCode': {
      'item': [],
      'data': ['']
    },
    'employerCity': {
      'item': [],
      'data': ['']
    },
    'employerCountry': {
      'item': [],
      'data': ['']
    },
    'activitySector': {
      'item': [],
      'data': ['']
    },
    'startingDateForProfessionals': {
      'item': [],
      'data': ['']
    },
    'seniority': {
      'item': [],
      'data': ['']
    },
    'geographicPeremeter': {
      'item': [],
      'data': ['']
    },
    'otherActivities': {
      'item': [],
      'data': ['']
    },
    'annualIncome': {
      'item': [],
      'data': ['']
    },
    'purpose': {
      'item': [],
      'data': [''],
      'other': ''
    },
    'countryFondsIn': {
      'item': [],
      'data': ['']
    },
    'countryFondsOut': {
      'item': [],
      'data': ['']
    },
    'depositary': {
      'item': [],
      'data': ['']
    },
    'depositionAtBKB': {
      'item': [],
      'data': ['']
    },
    'AccountNumber': {
      'item': [],
      'data': ['']
    },
    'clientType': {
      'item': [],
      'data': ['']
    },
    'clientJobType': {
      'item': [],
      'data': ['']
    },
    'codeID': {
      'item': [],
      'data': ['']
    },
    'typeID': {
      'item': [],
      'data': ['']
    },
    'autority': {
      'item': [],
      'data': ['']
    },
    'emissionDate': {
      'item': [],
      'data': ['']
    },
    'expirationDate': {
      'item': [],
      'data': ['']
    },
    'legalRepresentative': {
      'item': [],
      'data': ['']
    },
    'registrationCourt': {
      'item': [],
      'data': ['']
    },
    'NProfessionalTax': {
      'item': [],
      'data': ['']
    },
    'RNAE': {
      'item': [],
      'data': ['']
    },
    'RC': {
      'item': [],
      'data': ['']
    },
    'contractDenom': {
      'item': [],
      'data': ['']
    },
    'contractImmatCentre': {
      'item': [],
      'data': ['']
    },
    'contractNumPatente': {
      'item': [],
      'data': ['']
    },
    'RCPlaceDeliver': {
      'item': [],
      'data': ['']
    },
    'ICE': {
      'item': [],
      'data': ['']
    },
    'FATCANationality': {
      'item': [],
      'data': ['']
    },
    'FATCAOtherNationality': {
      'item': [],
      'data': ['']
    },
    'FATCAAdress': {
      'item': [],
      'data': ['']
    },
    'FATCAPhone': {
      'item': [],
      'data': ['']
    },
    'FATCAAttorney': {
      'item': [],
      'data': ['']
    },
    'FATCAMoneytransfer': {
      'item': [],
      'data': ['']
    },
    'FATCAAccountUSA': {
      'item': [],
      'data': ['']
    },
    'FATCAGreenCard': {
      'item': [],
      'data': ['']
    },
    'FRCStatut': {
      'item': [],
      'data': ['']
    },
    'FRCExtent': {
      'item': [],
      'data': ['']
    },

    'FRCName': {
      'item': [],
      'data': ['']
    },
    'FRCFname': {
      'item': [],
      'data': ['']
    },
    'FRCRelation': {
      'item': [],
      'data': ['']
    },
    'FRCNationality': {
      'item': [],
      'data': ['']
    },
    'FRCResidence': {
      'item': [],
      'data': ['']
    },
    'FRCIdType': {
      'item': [],
      'data': ['']
    },
    'FRCIdCode': {
      'item': [],
      'data': ['']
    },
    'FRCDeliver': {
      'item': [],
      'data': ['']
    },
    'FRCDate': {
      'item': [],
      'data': ['']
    },
    'FRCValidity': {
      'item': [],
      'data': ['']
    },
    'FRCCode': {
      'item': [],
      'data': ['']
    },
    'PPEStatut': {
      'item': [],
      'data': ['']
    },
    'PPERelativeStatut': {
      'item': [],
      'data': ['']
    },
    'PPERelativeType': {
      'item': [],
      'data': ['']
    },
    'PPENationalityRelative': {
      'item': [],
      'data': ['']
    },
    'PPECountryResidence': {
      'item': [],
      'data': ['']
    },
    'FATCAStatut': {
      'item': [],
      'data': ['']
    },
    'PPEMandate': {
      'item': [],
      'data': ['']
    },
    'PPEEndDate': {
      'item': [],
      'data': ['']
    },
    'PPERelatives': {
      'item': [],
      'data': ['']
    },
    'PPEName': {
      'item': [],
      'data': ['']
    },
    'PPEFname': {
      'item': [],
      'data': ['']
    },
    'PPEBirthDate': {
      'item': [],
      'data': ['']
    },
    'PPEBirthPlace': {
      'item': [],
      'data': ['']
    },
    'PPEJob': {
      'item': [],
      'data': ['']
    },
    'PPEJobRelative': {
      'item': [],
      'data': ['']
    },
    'PPEJobCountry': {
      'item': [],
      'data': ['']
    },
    'PPEEndDateRelative': {
      'item': [],
      'data': ['']
    },
    'PPEJobCountryRelative': {
      'item': [],
      'data': ['']
    },
    'PPERelative': {
      'item': [],
      'data': ['']
    },
    'PPEIdEntity': {
      'item': [],
      'data': ['']
    },
    'PPERelativeNatureLink': {
      'item': [],
      'data': ['']
    },
    'PPEOtherInformation': {
      'item': [],
      'data': ['']
    },
    'PPEExpirationDate': {
      'item': [],
      'data': ['']
    },
    'PPEEmissionDate': {
      'item': [],
      'data': ['']
    },
    'PPEDeliveranceCountry': {
      'item': [],
      'data': ['']
    },

    'EVBank1': {
      'item': [],
      'data': ['']
    },
    'EVAgency1': {
      'item': [],
      'data': ['']
    },
    'EVCity1': {
      'item': [],
      'data': ['']
    },
    'EVRIB1': {
      'item': [],
      'data': ['']
    },
    'EVBank2': {
      'item': [],
      'data': ['']
    },
    'EVAgency2': {
      'item': [],
      'data': ['']
    },
    'EVCity2': {
      'item': [],
      'data': ['']
    },
    'EVRIB2': {
      'item': [],
      'data': ['']
    },
    'revenues': {
      'item': [],
      'data': ['']
    },
    'revenuesSource': {
      'item': [],
      'data': [''],
      'other': ''
    },
    'patrmonyDistribution': {
      'item': [],
      'data': [''],
      'other': ''
    },
    'investementHorizon': {
      'item': [],
      'data': [''],
      'other': ''
    },
    'interestedSectors': {
      'item': [],
      'data': [''],
      'other': ''
    },
    'transaction': {
      'item': [],
      'data': [''],
      'other': ''
    },
    'profitPolitics': {
      'item': [],
      'data': [''],
      'other': ''
    },
    'investorProfil': {
      'item': [],
      'data': ['']
    },
    'investorKnowledge': {
      'item': [],
      'data': ['']
    },
    'investorInterventions': {
      'item': [],
      'data': [''],
      'other': ''
    },
    'portfolioRotation': {
      'item': [],
      'data': ['']
    },
    'moneyLost': {
      'item': [],
      'data': ['']
    },
    'effectiveBeneficiary': {
      'item': [],
      'data': ['']
    },
    'Statement': {
      'item': [],
      'data': ['']
    },
    'MadeIn': {
      'item': [],
      'data': ['']
    },
    'MadeOn': {
      'item': [],
      'data': ['']
    }
  },

  DATA_TO_SEND_ATTORNY_2: {
    'idCardUploaded': false,
    'userToken': '',
    'statusClient': '',

    'NewRelationship': {
      'item': [],
      'data': ['']
    },
    'maritalStatus': {
      'item': [],
      'data': ['']
    },
    'Update': false,
    'offredServices': {
      'item': [],
      'data': ['']
    },

    'ClientN': {
      'item': [],
      'data': ['']
    },
    'channelRelation': {
      'item': [],
      'data': ['']
    },
    'typologieClient': {
      'item': [],
      'data': ['']
    },
    'numTiers': {
      'item': [],
      'data': ['']
    },
    'name': {
      'item': [],
      'data': ['']
    },
    'fName': {
      'item': [],
      'data': ['']
    },
    'gender': {
      'item': [],
      'data': ['']
    },
    'birthDate': {
      'item': [],
      'data': ['']
    },
    'birthCity': {
      'item': [],
      'data': ['']
    },
    'birthCountry': {
      'item': [],
      'data': ['']
    },
    'nationality': {
      'item': [],
      'data': ['']
    },
    'statusMartial': {
      'item': [],
      'data': ['']
    },
    'secNationality': {
      'item': [],
      'data': ['']
    },
    'checkCorrespLegalAdress': {
      'item': [],
      'data': ['']
    },
    'idCountry': {
      'item': [],
      'data': ['']
    },
    'idValidDate1': {
      'item': [],
      'data': ['']
    },
    'legalRepresentativeName': {
      'item': [],
      'data': [
        ''
      ]
    },
    'legalRepresentativeQuality': {
      'item': [],
      'data': [
        ''
      ]
    },
    'idValidDate2': {
      'item': [],
      'data': ['']
    },
    'phoneNum': {
      'item': [],
      'data': ['']
    },
    'phoneCountry': {
      'item': [],
      'data': ['']
    },
    'FaxNum': {
      'item': [],
      'data': ['']
    },
    'email': {
      'item': [],
      'data': ['']
    },
    'fatherName': {
      'item': [],
      'data': ['']
    },
    'fatherFname': {
      'item': [],
      'data': ['']
    },
    'motherName': {
      'item': [],
      'data': ['']
    },
    'motherFname': {
      'item': [],
      'data': ['']
    },
    'currentAdress': {
      'item': [],
      'data': ['']
    },
    'taxAdress': {
      'item': [],
      'data': ['']
    },
    'taxZipCode': {
      'item': [],
      'data': ['']
    },
    'clientPhone': {
      'item': [],
      'data': ['']
    },
    'countryResidence': {
      'item': [],
      'data': ['']
    },
    'cityResidence': {
      'item': [],
      'data': ['']
    },
    'zipCodeResidence': {
      'item': [],
      'data': ['']
    },
    'justifResidence': {
      'item': [],
      'data': ['']
    },
    'otherResidence': {
      'item': [],
      'data': ['']
    },
    'correspAdress': {
      'item': [],
      'data': ['']
    },
    'correspZipCode': {
      'item': [],
      'data': ['']
    },
    'correspCity': {
      'item': [],
      'data': ['']
    },
    'correspCountry': {
      'item': [],
      'data': ['']
    },
    'correspBPAdress': {
      'item': [],
      'data': ['']
    },
    'countryTaxResidence': {
      'item': [],
      'data': ['']
    },
    'countryNative': {
      'item': [],
      'data': ['']
    },
    'job': {
      'item': [],
      'data': ['']
    },
    'prevJobCity': {
      'item': [],
      'data': ['']
    },
    'prevJobCountry': {
      'item': [],
      'data': ['']
    },
    'contractActivity': {
      'item': [],
      'data': ['']
    },
    'contractIncome': {
      'item': [],
      'data': ['']
    },
    'contractOriginIncome': {
      'item': [],
      'data': ['']
    },
    'employer': {
      'item': [],
      'data': ['']
    },
    'employerAdress': {
      'item': [],
      'data': ['']
    },
    'employerCountry': {
      'item': [],
      'data': ['']
    },
    'employerCity': {
      'item': [],
      'data': ['']
    },
    'employerZibCode': {
      'item': [],
      'data': ['']
    },
    'activitySector': {
      'item': [],
      'data': ['']
    },
    'startingDateForProfessionals': {
      'item': [],
      'data': ['']
    },
    'seniority': {
      'item': [],
      'data': ['']
    },
    'geographicPeremeter': {
      'item': [],
      'data': ['']
    },
    'otherActivities': {
      'item': [],
      'data': ['']
    },
    'annualIncome': {
      'item': [],
      'data': ['']
    },
    'purpose': {
      'item': [],
      'data': [''],
      'other': ''
    },
    'countryFondsIn': {
      'item': [],
      'data': ['']
    },
    'countryFondsOut': {
      'item': [],
      'data': ['']
    },
    'depositary': {
      'item': [],
      'data': ['']
    },
    'depositionAtBKB': {
      'item': [],
      'data': ['']
    },
    'AccountNumber': {
      'item': [],
      'data': ['']
    },
    'clientType': {
      'item': [],
      'data': ['']
    },
    'clientJobType': {
      'item': [],
      'data': ['']
    },
    'codeID': {
      'item': [],
      'data': ['']
    },
    'typeID': {
      'item': [],
      'data': ['']
    },
    'autority': {
      'item': [],
      'data': ['']
    },
    'emissionDate': {
      'item': [],
      'data': ['']
    },
    'expirationDate': {
      'item': [],
      'data': ['']
    },
    'legalRepresentative': {
      'item': [],
      'data': ['']
    },
    'registrationCourt': {
      'item': [],
      'data': ['']
    },
    'NProfessionalTax': {
      'item': [],
      'data': ['']
    },
    'RNAE': {
      'item': [],
      'data': ['']
    },
    'RC': {
      'item': [],
      'data': ['']
    },
    'contractDenom': {
      'item': [],
      'data': ['']
    },
    'contractImmatCentre': {
      'item': [],
      'data': ['']
    },
    'contractNumPatente': {
      'item': [],
      'data': ['']
    },
    'RCPlaceDeliver': {
      'item': [],
      'data': ['']
    },
    'ICE': {
      'item': [],
      'data': ['']
    },
    'FATCANationality': {
      'item': [],
      'data': ['']
    },
    'FATCAOtherNationality': {
      'item': [],
      'data': ['']
    },
    'FATCAAdress': {
      'item': [],
      'data': ['']
    },
    'FATCAPhone': {
      'item': [],
      'data': ['']
    },
    'FATCAAttorney': {
      'item': [],
      'data': ['']
    },
    'FATCAMoneytransfer': {
      'item': [],
      'data': ['']
    },
    'FATCAAccountUSA': {
      'item': [],
      'data': ['']
    },
    'FATCAGreenCard': {
      'item': [],
      'data': ['']
    },
    'FRCStatut': {
      'item': [],
      'data': ['']
    },
    'FRCExtent': {
      'item': [],
      'data': ['']
    },

    'FRCName': {
      'item': [],
      'data': ['']
    },
    'FRCFname': {
      'item': [],
      'data': ['']
    },
    'FRCRelation': {
      'item': [],
      'data': ['']
    },
    'FRCNationality': {
      'item': [],
      'data': ['']
    },
    'FRCResidence': {
      'item': [],
      'data': ['']
    },
    'FRCIdType': {
      'item': [],
      'data': ['']
    },
    'FRCIdCode': {
      'item': [],
      'data': ['']
    },
    'FRCDeliver': {
      'item': [],
      'data': ['']
    },
    'FRCDate': {
      'item': [],
      'data': ['']
    },
    'FRCValidity': {
      'item': [],
      'data': ['']
    },
    'FRCCode': {
      'item': [],
      'data': ['']
    },
    'PPEStatut': {
      'item': [],
      'data': ['']
    },
    'PPERelativeStatut': {
      'item': [],
      'data': ['']
    },
    'PPERelativeType': {
      'item': [],
      'data': ['']
    },
    'PPECountryResidence': {
      'item': [],
      'data': ['']
    },
    'PPENationalityRelative': {
      'item': [],
      'data': ['']
    },
    'FATCAStatut': {
      'item': [],
      'data': ['']
    },
    'PPEMandate': {
      'item': [],
      'data': ['']
    },
    'PPEEndDate': {
      'item': [],
      'data': ['']
    },
    'PPERelatives': {
      'item': [],
      'data': ['']
    },
    'PPEName': {
      'item': [],
      'data': ['']
    },
    'PPEFname': {
      'item': [],
      'data': ['']
    },
    'PPEBirthDate': {
      'item': [],
      'data': ['']
    },
    'PPEBirthPlace': {
      'item': [],
      'data': ['']
    },
    'PPEJob': {
      'item': [],
      'data': ['']
    },
    'PPEJobRelative': {
      'item': [],
      'data': ['']
    },
    'PPEJobCountry': {
      'item': [],
      'data': ['']
    },
    'PPEEndDateRelative': {
      'item': [],
      'data': ['']
    },
    'PPEJobCountryRelative': {
      'item': [],
      'data': ['']
    },
    'PPERelative': {
      'item': [],
      'data': ['']
    },
    'PPEIdEntity': {
      'item': [],
      'data': ['']
    },
    'PPERelativeNatureLink': {
      'item': [],
      'data': ['']
    },
    'PPEOtherInformation': {
      'item': [],
      'data': ['']
    },
    'PPEExpirationDate': {
      'item': [],
      'data': ['']
    },
    'PPEEmissionDate': {
      'item': [],
      'data': ['']
    },
    'PPEDeliveranceCountry': {
      'item': [],
      'data': ['']
    },

    'EVBank1': {
      'item': [],
      'data': ['']
    },
    'EVAgency1': {
      'item': [],
      'data': ['']
    },
    'EVCity1': {
      'item': [],
      'data': ['']
    },
    'EVRIB1': {
      'item': [],
      'data': ['']
    },
    'EVBank2': {
      'item': [],
      'data': ['']
    },
    'EVAgency2': {
      'item': [],
      'data': ['']
    },
    'EVCity2': {
      'item': [],
      'data': ['']
    },
    'EVRIB2': {
      'item': [],
      'data': ['']
    },
    'revenues': {
      'item': [],
      'data': ['']
    },
    'revenuesSource': {
      'item': [],
      'data': [''],
      'other': ''
    },
    'patrmonyDistribution': {
      'item': [],
      'data': [''],
      'other': ''
    },
    'investementHorizon': {
      'item': [],
      'data': [''],
      'other': ''
    },
    'interestedSectors': {
      'item': [],
      'data': [''],
      'other': ''
    },
    'transaction': {
      'item': [],
      'data': [''],
      'other': ''
    },
    'profitPolitics': {
      'item': [],
      'data': [''],
      'other': ''
    },
    'investorProfil': {
      'item': [],
      'data': ['']
    },
    'investorKnowledge': {
      'item': [],
      'data': ['']
    },
    'investorInterventions': {
      'item': [],
      'data': [''],
      'other': ''
    },
    'portfolioRotation': {
      'item': [],
      'data': ['']
    },
    'moneyLost': {
      'item': [],
      'data': ['']
    },
    'effectiveBeneficiary': {
      'item': [],
      'data': ['']
    },
    'Statement': {
      'item': [],
      'data': ['']
    },
    'MadeIn': {
      'item': [],
      'data': ['']
    },
    'MadeOn': {
      'item': [],
      'data': ['']
    }
  },
};

export const DataToShow = {

  DATA_TO_SHOW: {
    'rdv_date': [
      {
        title: 'Votre rendez-vous',
        icon: 'call',
        contentType: 'fact_check',
        dontshowFooter: true,
        indication: 'Votre dossier est en cours d\'étude, un conseiller va prendre contact avec vous pour le valider et vous permettre d\'accéder à la dernière étape de signature électornique de votre questionnaire et convention. Nous vous remercions pour votre confiance. '
      }
    ],
    'bienvenueBk': [
      {
        title: 'Bienvenue à BMCE Capital Bourse',
        icon: 'sentiment_very_satisfied',
        contentType: 'fact_check',
        dontshowFooter: true,
        indication: 'Merci de télecharger et garder une copie du contrat. Vous pouvez télécharger votre copie en cliquant sur le lien ci-dessous.'
      }
    ],
    'checkContrat': [
      {
        title: 'Lisez et validez les données de votre questionnaire',
        icon: 'fact_check',
        contentType: 'fact_check',
        dontshowFooter: true
      },
      {
        title: 'Lisez et validez les données de votre convention',
        icon: 'fact_check',
        contentType: 'fact_check',
        dontshowFooter: true
      },
    ],
    'checkContratMandataire': [
      {
        title: 'Lisez et validez les données de votre questionnaire',
        icon: 'fact_check',
        contentType: 'fact_check',
        dontshowFooter: true
      },
      {
        title: 'Lisez et validez les données de questionnaire du mandataire',
        icon: 'fact_check',
        contentType: 'fact_check',
        dontshowFooter: true
      },
      {
        title: 'Lisez et validez les données de votre convention',
        icon: 'fact_check',
        contentType: 'fact_check',
        dontshowFooter: true
      }
    ],
    'signeContrat': [
      {
        title: 'Signer votre questionnaire',
        icon: 'fact_check',
        contentType: 'fact_check',
        dontshowFooter: true
      },
      {
        title: 'Signer le questionnaire du mandataire',
        icon: 'fact_check',
        contentType: 'fact_check',
        dontshowFooter: true
      },
    ],
    'signeContratMandatire': [
      {
        title: 'Signer votre questionnaire',
        icon: 'fact_check',
        contentType: 'fact_check',
        dontshowFooter: true
      },
      {
        title: 'Signer le questionnaire du mandataire',
        icon: 'fact_check',
        contentType: 'fact_check',
        dontshowFooter: true
      },
      {
        title: 'Signer votre convention',
        icon: 'fact_check',
        contentType: 'fact_check',
        dontshowFooter: true
      }
    ],
    'clientAlreadyExist': [
      {
        title: 'Renseignez votre adresse mail',
        icon: 'alternate_email',
        contentType: 'adresse-mail',
        indication: 'Un email vous sera envoyé afin de confirmer vos coordonnées électroniques',
        step: {
          title: 'Votre adresse Mail',
          index: 0
        }
      },
      {
        title: 'Compte Existe Déjà !',
        icon: 'wb_twilight',
        contentType: 'app-password',
        indication: 'Adresse mail deja Existe ! Veuillez taper votre mot de passe, pour compléter votre souscription.',
        step: {
          title: 'Votre Mot de passe',
          index: 2
        },
      },
    ],
    'eligibilite': [
      {
        title: 'Renseignez votre adresse mail',
        icon: 'alternate_email',
        contentType: 'adresse-mail',
        indication: 'Un email vous sera envoyé afin de confirmer vos coordonnées électroniques',
        step: {
          title: 'Votre adresse Mail',
          index: 0
        }
      },
      {
        title: 'Veuillez vérifier votre boîte de réception',
        icon: 'mark_email_unread',
        contentType: 'confirme-mail',
        indication: 'Veuillez vérifier votre boîte de réception, un mail a été envoyé pour valider vos coordonnées électroniques.',
        step: {
          title: 'Votre adresse Mail',
          index: 1
        }
      },
      {
        title: 'Veuillez Taper votre mot de passe',
        icon: 'lock_open',
        contentType: 'app-password',
        indication: 'Veuillez Tapez un mot de passe afin de garantir la sècurité de votre compte BKB.',
        step: {
          title: 'Votre Mot de passe',
          index: 2
        },
      },
    ],
    'categories': [
      {
        title: 'Pièces Justificatives',
        contentType: 'idUpload',
        icon: 'portrait',
      },
  
      
      {
        title: 'Votre Selfie',
        contentType: 'facewebcam',
        icon: 'camera_enhance',
      },
      {
        title: 'Généralités',
        icon: 'person_search',
        contentType: 'stardard',
        controlAttr: 'clientDetails',
        elements: [
          {
            colspan: 1,
            rowspan: 1,
            jsonAttr: 'name',
            title: 'Nom',
            inputType: 'input',
            parentJsonAttr: 'noParent',
            valueToBeVisible: [],
          },
          {
            colspan: 1,
            rowspan: 1,
            jsonAttr: 'fName',
            title: 'Prénom',
            inputType: 'input',
            parentJsonAttr: 'noParent',
            valueToBeVisible: [],

          },
          {
            colspan: 1,
            rowspan: 1,
            jsonAttr: 'gender',
            title: 'Genre',
            inputType: 'oneChoice',
            choices: QuizStaticVariable.GENDER,
            parentJsonAttr: 'noParent',
            valueToBeVisible: [],

          },
          {
            colspan: 1,
            rowspan: 1,
            jsonAttr: 'birthDate',
            title: 'Date de naissance',
            minDate: new Date(1900, 1, 1),
            maxDate: new Date(),
            inputType: 'datePicker',
            parentJsonAttr: 'noParent',
            valueToBeVisible: [],

          },
          {
            colspan: 1,
            rowspan: 1,
            jsonAttr: 'birthCity',
            title: 'Ville de naissance',
            inputType: 'input',
            parentJsonAttr: 'noParent',
            valueToBeVisible: [],

          },
          {
            colspan: 1,
            rowspan: 1,
            jsonAttr: 'birthCountry',
            title: 'Pays de naissance',
            inputType: 'oneChoice',
            choices: QuizStaticVariable.COUNTRIES,
            parentJsonAttr: 'noParent',
            valueToBeVisible: [],

          },
          {
            colspan: 1,
            rowspan: 1,
            jsonAttr: 'nationality',
            title: 'Pays de nationalité',
            inputType: 'oneChoice',
            choices: QuizStaticVariable.COUNTRIES,
            parentJsonAttr: 'noParent',
            valueToBeVisible: [],

          },
          {
            colspan: 1,
            rowspan: 1,
            jsonAttr: 'secNationality',
            title: 'Pays de deuxieme nationalité ',
            inputType: 'oneChoice',
            choices: QuizStaticVariable.COUNTRIES,
            parentJsonAttr: 'noParent',
            valueToBeVisible: [],

          },
          {
            colspan: 1,
            rowspan: 1,
            jsonAttr: 'maritalStatus',
            title: 'Situation Matrimoniale',
            inputType: 'oneChoice',
            choices: QuizStaticVariable.MARITAL_STATUS,
            parentJsonAttr: 'noParent',
            valueToBeVisible: [],

          },
          {
            colspan: 1,
            rowspan: 1,
            jsonAttr: 'clientType',
            title: 'Type du client ',
            inputType: 'oneChoice',
            choices: QuizStaticVariable.CLIENT_TYPE,
            parentJsonAttr: 'noParent',
            valueToBeVisible: [],

          },
          {
            colspan: 1,
            rowspan: 1,
            jsonAttr: 'codeID',
            title: 'Numéro de la pièce d\'identité',
            inputType: 'input',
            parentJsonAttr: 'noParent',
            valueToBeVisible: [],

          },
          {
            colspan: 1,
            rowspan: 1,
            jsonAttr: 'autority',
            title: 'Autorité de délivrance',
            inputType: 'oneChoice',
            choices: QuizStaticVariable.COUNTRIES,
            parentJsonAttr: 'noParent',
            valueToBeVisible: [],
          },
          {
            colspan: 1,
            rowspan: 1,
            jsonAttr: 'emissionDate',
            title: 'Date d\'émission',
            inputType: 'datePicker',
            minDate: new Date(1900, 1, 1),
            maxDate: new Date(),
            parentJsonAttr: 'noParent',
            valueToBeVisible: [],
          },
          {
            colspan: 1,
            rowspan: 1,
            jsonAttr: 'expirationDate',
            title: 'Date d\'expiration',
            inputType: 'datePicker',
            minDate: new Date(),
            maxDate: new Date(2100, 1, 1),
            parentJsonAttr: 'noParent',
            valueToBeVisible: [],
          },
         /* {

            colspan: 1,
            rowspan: 1,
            jsonAttr: '',
            title: '',
          },*/
          {

            colspan: 1,
            rowspan: 1,
            jsonAttr: 'legalRepresentativeName',
            title: 'Nom du représentant légal \n(si applicable)',
            inputType: 'input',
            parentJsonAttr: 'noParent',
            valueToBeVisible: [],
          },
          {
            colspan: 1,
            rowspan: 1,
            jsonAttr: 'legalRepresentativeQuality',
            title: 'Qualité du représentant légal \n(si applicable)',
            inputType: 'input',
            parentJsonAttr: 'noParent',
            valueToBeVisible: [],

          }
        ]
      },
      {
        title: 'Vos coordonnées',
        icon: 'person_pin_circle',
        contentType: 'stardard',
        controlAttr: 'clientContact',
        elements: [

          {
            colspan: 1,
            rowspan: 1,
            jsonAttr: 'phoneNum',
            title: 'Tél mobile',
            inputType: 'input',
            parentJsonAttr: 'noParent',
            valueToBeVisible: [],

          },
          {
            colspan: 1,
            rowspan: 1,
            jsonAttr: 'FaxNum',
            title: 'Téléphone 2',
            inputType: 'input',
            parentJsonAttr: 'noParent',
            valueToBeVisible: [],

          },
        /*  {
            colspan: 1,
            rowspan: 1,
            jsonAttr: 'email',
            title: 'Adresse e-mail',
            inputType: 'input',
            parentJsonAttr: 'noParent',
            valueToBeVisible: [],

          },*/
      /*    {
            colspan: 1,
            rowspan: 1,
            jsonAttr: 'fatherFname',
            title: 'Prénom du père',
            inputType: 'input',
            parentJsonAttr: 'noParent',
            valueToBeVisible: [],

          },
          {
            colspan: 1,
            rowspan: 1,
            jsonAttr: 'fatherName',
            title: 'Nom du père',
            inputType: 'input',
            parentJsonAttr: 'noParent',
            valueToBeVisible: [],

          },
          {
            colspan: 1,
            rowspan: 1,
            jsonAttr: 'motherFname',
            title: 'Prénom de la mère',
            inputType: 'input',
            parentJsonAttr: 'noParent',
            valueToBeVisible: [],

          },
          {
            colspan: 1,
            rowspan: 1,
            jsonAttr: 'motherName',
            title: 'Nom de la mère',
            inputType: 'input',
            parentJsonAttr: 'noParent',
            valueToBeVisible: [],

          },
*/

          {
            colspan: 1,
            rowspan: 1,
            jsonAttr: 'currentAdress',
            title: 'Adresse de résidence',
            inputType: 'input',
            parentJsonAttr: 'noParent',
            valueToBeVisible: [],

          },
          {
            colspan: 1,
            rowspan: 1,
            jsonAttr: 'zipCodeResidence',
            title: 'code postale résidence',
            inputType: 'input',
            parentJsonAttr: 'noParent',
            valueToBeVisible: [],

          },
          {
            colspan: 1,
            rowspan: 1,
            jsonAttr: 'cityResidence',
            title: 'Ville de résidence',
            inputType: 'input',
            parentJsonAttr: 'noParent',
            valueToBeVisible: [],

          },
          {
            colspan: 1,
            rowspan: 1,
            jsonAttr: 'countryResidence',
            title: 'Pays de résidence',
            inputType: 'oneChoice',
            choices: QuizStaticVariable.COUNTRIES,
            parentJsonAttr: 'noParent',
            valueToBeVisible: [],


          },
          {
            colspan: 2,
            rowspan: 1,
            inputType: 'drawDivider',
            parentJsonAttr: 'noParent',
            valueToBeVisible: [],

          },
          {
            colspan: 2,
            rowspan: 1,
            jsonAttr: 'checkCorrespLegalAdress',
            title: 'Est-ce-que votre adresse de correspondance est différente de votre adresse légale ?',
            inputType: 'oneChoiceWithControl',
            jsonAttrToRemove: 'flag',
            choices: QuizStaticVariable.OUI_NON,
            parentJsonAttr: 'noParent',
            valueToBeVisible: [],

          }
        ]
      },
      {
        title: 'Votre environnement professionnel',
        icon: 'ballot',
        contentType: 'stardard',
        controlAttr: 'clientJob',
        elements: [
          {
            colspan: 1,
            rowspan: 1,
            jsonAttr: 'clientJobType',
            title: 'Type de profession du client ',
            inputType: 'oneChoiceWithControl',
            jsonAttrToRemove: 'flag',
            parentJsonAttr: 'noParent',
            valueToBeVisible: [],
            choices: QuizStaticVariable.CLIENT_JOB_TYPE,
          },
        ]
      },
      {
        title: 'Votre environnement bancaire',
        icon: 'account_balance',
        contentType: 'stardard',
        controlAttr: 'clientBank',
        addElement: false,
        addElementKey: 'EVB',
        addElementIndexMax: 1,
        addElementIndex: 1,
        elements: [
          {
            colspan: 1,
            rowspan: 1,
            jsonAttr: 'EVBank1',
            title: 'BANQUE',
            inputType: 'oneChoiceWithText',
            choices: QuizStaticVariable.EV_BANK,
            parentJsonAttr: 'noParent',
            valueToBeVisible: [],
          },
          {
            colspan: 1,
            rowspan: 1,
            jsonAttr: 'EVAgency1',
            title: 'Agence bancaire',
            inputType: 'input',
            parentJsonAttr: 'noParent',
            valueToBeVisible: [],
          },
          {
            colspan: 1,
            rowspan: 1,
            jsonAttr: 'EVCity1',
            title: 'ville',
            inputType: 'input',
            parentJsonAttr: 'noParent',
            valueToBeVisible: [],
          },
          {
            colspan: 1,
            rowspan: 1,
            jsonAttr: 'EVRIB1',
            maxNumber:24,
            title: 'RIB',
            inputType: 'input',
            parentJsonAttr: 'noParent',
            valueToBeVisible: [],
          }
        ]
      },
      {
        title: 'Votre activité',
        icon: 'trending_up',
        contentType: 'stardard',
        controlAttr: 'clientActivity',
        elements: [
          {
            colspan: 1,
            rowspan: 1,
            jsonAttr: 'job',
            title: 'Profession',
            inputType: 'oneChoice',
            choices: QuizStaticVariable.JOB,
            parentJsonAttr: 'noParent',
            valueToBeVisible: [],

          },
          {
            colspan: 1,
            rowspan: 1,
            jsonAttr: 'employer',
            title: 'Employeur',
            inputType: 'input',
            parentJsonAttr: 'noParent',
            valueToBeVisible: [],

          },
          {
            colspan: 1,
            rowspan: 1,
            jsonAttr: 'employerAdress',
            title: 'Adresse de l\'employeur',
            inputType: 'input',
            valueToBeVisible: [],
          },
          {
            jsonAttr: 'employerZibCode',
            title: 'code postal',
            inputType: 'input',
            valueToBeVisible: [],
            colspan: 1,
            rowspan: 1,

          },
          {
            jsonAttr: 'employerCity',
            title: 'Ville',
            inputType: 'input',
            valueToBeVisible: [],
            colspan: 1,
            rowspan: 1,

          },
          {
            valueToBeVisible: [],
            colspan: 1,
            rowspan: 1,
            jsonAttr: 'employerCountry',
            title: 'pays',
            inputType: 'oneChoice',
            choices: QuizStaticVariable.COUNTRIES,
          },
          {
            colspan: 1,
            rowspan: 1,
            jsonAttr: 'activitySector',
            title: 'Secteur d\'activité',
            inputType: 'oneChoice',
            choices: QuizStaticVariable.ACTIVITY_TYPE,
            parentJsonAttr: 'noParent',
            valueToBeVisible: [],


          }, {
            colspan: 1,
            rowspan: 1,
            jsonAttr: 'seniority',
            title: 'Ancienneté (nombre d\'année)',
            inputType: 'numberPicker',
            parentJsonAttr: 'noParent',
            valueToBeVisible: [],

          },
          {
            colspan: 1,
            rowspan: 1,
            jsonAttr: 'startingDateForProfessionals',
            title: 'Date début de l\'activité pour les professionels',
            inputType: 'datePicker',
            minDate: new Date(1900, 1, 1),
            maxDate: new Date(),
            parentJsonAttr: 'noParent',
            valueToBeVisible: [],
          },
          {
            colspan: 1,
            rowspan: 1,
            jsonAttr: 'geographicPeremeter',
            title: 'Zone d\'activité du client',
            inputType: 'oneChoice',
            choices: QuizStaticVariable.COUNTRIES,
            parentJsonAttr: 'noParent',
            valueToBeVisible: [],
          }, {
            colspan: 1,
            rowspan: 1,
            jsonAttr: 'otherActivities',
            title: 'Autre activité',
            inputType: 'input',
            parentJsonAttr: 'noParent',
            valueToBeVisible: [],
          }
        ]
      },
      {
        title: 'Votre dépositaire',
        icon: 'person_search',
        contentType: 'stardard',
        controlAttr: 'depository',
        elements: [
          {
            colspan: 1,
            rowspan: 1,
            jsonAttr: 'depositary',
            title: 'Dépositaire ',
            inputType: 'oneChoice',
            choices: QuizStaticVariable.DEPOSITAIRE,
            parentJsonAttr: 'noParent',
            valueToBeVisible: [],
          },
          {
            colspan: 1,
          },

          /*{
            colspan: 1,
            rowspan: 1,
            jsonAttr: 'depositionAtBKB',
            title: 'Disposition d’un compte ouvert chez BKB',
            inputType: 'oneChoiceWithText',
            inputTextVisible: false,
            hintText: 'N° compte BKB',
            choices: QuizStaticVariable.OUI_NON_WITH_TEXT,
            parentJsonAttr: 'noParent',
            valueToBeVisible: [],
          }*/
        ]
      },

      {
        title: 'Attributs FATCA (Foreign Account Tax Compliance)',
        icon: 'person_search',
        contentType: 'stardard',
        controlAttr: 'FATCA',
        elements: [
          {
            colspan: 1,
            rowspan: 1,
            jsonAttr: 'FATCANationality',
            title: 'Etes-vous un citoyen américain (Nationalité ou Résidence) ?',
            inputType: 'oneChoiceWithText',
            choices: QuizStaticVariable.OUI_NON,
            parentJsonAttr: 'noParent',
            valueToBeVisible: [],
           

          }, {
            colspan: 1,
            rowspan: 1,
            jsonAttr: 'FATCAOtherNationality',
            title: 'Avez-vous nationalité américaine ?',
            inputType: 'oneChoiceWithText',
            choices: QuizStaticVariable.OUI_NON,
            parentJsonAttr: 'noParent',
            valueToBeVisible: [],
          },
          {
            colspan: 1,
            rowspan: 1,
            jsonAttr: 'FATCAAdress',
            title: 'Avez-vous une adresse/domicile/courrier aux USA ?',
            inputType: 'oneChoiceWithText',
            inputTextVisible: false,
            hintText: 'Adresse',
            choices: QuizStaticVariable.OUI_NON_WITH_TEXT,
            parentJsonAttr: 'noParent',
            valueToBeVisible: [],
          },
          {
            colspan: 1,
            rowspan: 1,
            jsonAttr: 'FATCAPhone',
            title: 'Avez vous une ligne téléphonique américaine ?',
            inputType: 'oneChoiceWithText',
            inputTextVisible: false,
            hintText: 'Numéro de téléphone',
            choices: QuizStaticVariable.OUI_NON_WITH_TEXT,
            parentJsonAttr: 'noParent',
            valueToBeVisible: [],
          },
          
          {
            colspan: 1,
            rowspan: 1,
            jsonAttr: 'FATCAAccountUSA',
            title: 'Disposez-vous de comptes aux USA ?',
            inputType: 'oneChoiceWithText',
            choices: QuizStaticVariable.OUI_NON,
            parentJsonAttr: 'noParent',
            valueToBeVisible: [],
          },
          {
            colspan: 1,
            rowspan: 1,
            jsonAttr: 'FATCAGreenCard',
            title: 'Disposez-vous d\'une Green Card ?',
            inputType: 'oneChoiceWithText',
            inputTextVisible: false,
            hintText: 'Green Card n°',
            choices: QuizStaticVariable.OUI_NON_WITH_TEXT,
            parentJsonAttr: 'noParent',
            valueToBeVisible: [],
          },

          {
            colspan: 2,
            rowspan: 1,
            jsonAttr: 'FATCAMoneytransfer',
            title: 'Effectuez-vous un virement permanent sur un ou plusieurs comptes aux US?',
            inputType: 'oneChoiceWithText',
            choices: QuizStaticVariable.OUI_NON,
            parentJsonAttr: 'noParent',
            valueToBeVisible: [],
          },
          {
            colspan: 2,
            rowspan: 1,
            jsonAttr: 'FATCAAttorney',
            title: 'Avez-vous octoyé une procuration ou un pouvoir de signature en cours de validité à une personne US?',
            inputType: 'oneChoiceWithText',
            inputTextVisible: false,
            hintText: 'Nom et prénom de la personne',
            choices: QuizStaticVariable.OUI_NON_WITH_TEXT,
            parentJsonAttr: 'noParent',
            valueToBeVisible: [],
            
          },
          
          
        ],
        
        
      },
      {
        title: 'Personne Politiquement Exposée (PPE)',
        icon: 'person_outline',
        contentType: 'stardard',
        controlAttr: 'PPE',
        elements: [
          {
            jsonAttr: 'PPEStatut',
            title: ' Êtes-vous PPE ?',
            inputType: 'oneChoiceWithControl',
            choices: QuizStaticVariable.OUI_NON,
            parentJsonAttr: 'noParent',
            jsonAttrToRemove: 'PPERelativeStatut',
            colspan: 1,
            rowspan: 1,
          },
        ]
      },
      {
        title: 'Situation financière et patrimoniale',
        icon: 'beach_access',
        contentType: 'stardard',
        controlAttr: 'financialSituation',
        elements: [
          {
            colspan: 2,
            rowspan: 1,
            jsonAttr: 'revenues',
            title: 'A combien estimez-vous le montant de vos revenus annuels moyens brut ?',
            inputType: 'oneChoice',
            choices: QuizStaticVariable.REVENUES,
            parentJsonAttr: 'noParent',
            valueToBeVisible: [],
          },
          {
            colspan: 2,
            rowspan: 1,
            jsonAttr: 'revenuesSource',
            title: 'Quels sont vos principaux sources de revenus ?',
            inputType: 'multipleChoiceWithText',
            choices: QuizStaticVariable.REVENUES_SOURCES,
            parentJsonAttr: 'noParent',
            valueToBeVisible: [],
          },
          {
            colspan: 2,
            rowspan: 1,
            jsonAttr: 'patrmonyDistribution',
            title: 'Quelles sont les composantes de votre patrimoine?',
            inputType: 'multipleChoiceWithText',
            choices: QuizStaticVariable.PATRMONY_DISTRIBUTION,
            parentJsonAttr: 'noParent',
            valueToBeVisible: [],
          },
        ]
      },
      {
        title: 'Connaissance en matière d\'investissement',
        icon: 'engineering',
        contentType: 'stardard',
        controlAttr: 'investementKnowledge',
        elements: [
          {
            colspan: 2,
            rowspan: 1,
            jsonAttr: 'purpose',
            title: 'Objet d’investissement/relation',
            inputType: 'multipleChoiceWithText',
            inputTextVisible: false,
            choices: QuizStaticVariable.RELATION_PURPOSE,
            parentJsonAttr: 'noParent',
            valueToBeVisible: [],

          },
          {
            colspan: 2,
            rowspan: 1,
            jsonAttr: 'investementHorizon',
            title: 'Quel est votre horizon d\'investissement ?',
            inputType: 'oneChoiceWithText',
            choices: QuizStaticVariable.INVESTEMENT_HORIZON,
            parentJsonAttr: 'noParent',
            valueToBeVisible: [],
          },
          {
            colspan: 2,
            rowspan: 1,
            jsonAttr: 'interestedSectors',
            title: 'A priori, quels sont les secteurs des sociétés cotées qui vous intéressent ?',
            inputType: 'multipleChoiceWithText',
            choices: QuizStaticVariable.INTERESTED_SECTORS,
            parentJsonAttr: 'noParent',
            valueToBeVisible: [],
          },
          {
            colspan: 2,
            rowspan: 1,
            jsonAttr: 'transaction',
            title: 'Quels sont les produits financiers qui vous intéressent ?',
            inputType: 'multipleChoiceWithText',
            choices: QuizStaticVariable.TRANSACTION,
            parentJsonAttr: 'noParent',
            valueToBeVisible: [],
          },
          {
            colspan: 2,
            rowspan: 1,
            jsonAttr: 'profitPolitics',
            title: 'Dans votre politique de profits, optez-vois pour :',
            inputType: 'multipleChoiceWithText',
            choices: QuizStaticVariable.PROFIT_POLITICS,
            parentJsonAttr: 'noParent',
            valueToBeVisible: [],
          },
          {
            colspan: 2,
            rowspan: 1,
            jsonAttr: 'investorProfil',
            title: 'Parmi les descriptifs suivants, \nlequel correspond le mieux à votre profil d\'investisseur ?',
            inputType: 'oneChoice',
            choices: QuizStaticVariable.INVESTOR_PROFIL,
            parentJsonAttr: 'noParent',
            valueToBeVisible: [],
          },
          {
            colspan: 2,
            rowspan: 1,
            jsonAttr: 'investorKnowledge',
            title: 'Quel est votre niveau de connaissances en matière d\'investissement sur le marché financier ?',
            inputType: 'oneChoice',
            choices: QuizStaticVariable.INVESTOR_KNOWLEDGE,
            parentJsonAttr: 'noParent',
            valueToBeVisible: [],
          },
          {
            colspan: 2,
            rowspan: 1,
            jsonAttr: 'investorInterventions',
            title: 'Votre intervention en Bourse sera effectuées :',
            inputType: 'multipleChoiceWithText',
            choices: QuizStaticVariable.INVESTOR_INTERVENTIONS,
            parentJsonAttr: 'noParent',
            valueToBeVisible: [],
          },
          {
            colspan: 2,
            rowspan: 1,
            jsonAttr: 'portfolioRotation',
            title: 'A combien estimez-vous la rotation de votre protefeuille durant les deux dernières années ?',
            inputType: 'oneChoice',
            choices: QuizStaticVariable.PORTFOLIO_ROTATION,
            parentJsonAttr: 'noParent',
            valueToBeVisible: [],
          },

          {
            colspan: 2,
            rowspan: 1,
            jsonAttr: 'moneyLost',
            title: 'Avez-vous déjà perdu des sommes significatives durant les deux dernières années ',
            inputType: 'oneChoice',
            choices: QuizStaticVariable.MONEY_LOST,
            parentJsonAttr: 'noParent',
            valueToBeVisible: [],
          },
          {
            colspan: 2,
            rowspan: 1,
            jsonAttr: 'effectiveBeneficiary',
            title: 'Etes-vous le bénéficiare effectif des opérations à exécuter ?',
            inputType: 'oneChoiceWithText',
            choices: QuizStaticVariable.EFFECTIVE_BENEFICIARY,
            parentJsonAttr: 'noParent',
            valueToBeVisible: [],
          }

        ]
      }

    ],
    'services': [
      {
        icon: 'home_repair_service',
        title: 'Nos services proposés',
        contentType: 'stardard',
        controlAttr: 'offredServices',
        indication: 'Veuillez selectionner un ou plusieurs services ',
        elements: [
          {
            colspan: 2,
            rowspan: 1,
            jsonAttr: 'offredServices',
            title: 'Pour quel(s) service(s) souhaitez-vous souscrire?',
            inputType: 'multipleChoiceHierarchy',
            choices: QuizStaticVariable.OFFRED_SERVICES,
            parentJsonAttr: 'noParent',
            valueToBeVisible: [],
          },]
      },
    ]
  },
  DATA_TO_SHOW_ATTORNY: {
    'categoriesMandatire': [
      {
        title: 'Pièce d\'identité',
        contentType: 'idUpload',
        icon: 'portrait',
      },
      {
        title: 'Généralités',
        icon: 'person_search',
        contentType: 'stardard',
        controlAttr: 'clientDetailsMandataire',
        elements: [
          {
            colspan: 1,
            rowspan: 1,
            jsonAttr: 'name',
            title: 'Nom',
            inputType: 'input',
            parentJsonAttr: 'noParent',
            valueToBeVisible: [],
          },
          {
            colspan: 1,
            rowspan: 1,
            jsonAttr: 'fName',
            title: 'Prénom',
            inputType: 'input',
            parentJsonAttr: 'noParent',
            valueToBeVisible: [],

          },

          {
            colspan: 1,
            rowspan: 1,
            jsonAttr: 'gender',
            title: 'Genre',
            inputType: 'oneChoice',
            choices: QuizStaticVariable.GENDER,
            parentJsonAttr: 'noParent',
            valueToBeVisible: [],

          },
          {
            colspan: 1,
            rowspan: 1,
            jsonAttr: 'birthDate',
            title: 'Date de naissance',
            minDate: new Date(1900, 1, 1),
            maxDate: new Date(),
            inputType: 'datePicker',
            parentJsonAttr: 'noParent',
            valueToBeVisible: [],

          },
          {
            colspan: 1,
            rowspan: 1,
            jsonAttr: 'birthCity',
            title: 'Ville de naissance',
            inputType: 'input',
            parentJsonAttr: 'noParent',
            valueToBeVisible: [],

          },
          {
            colspan: 1,
            rowspan: 1,
            jsonAttr: 'birthCountry',
            title: 'Pays de naissance',
            inputType: 'oneChoice',
            choices: QuizStaticVariable.COUNTRIES,
            parentJsonAttr: 'noParent',
            valueToBeVisible: [],

          },
          {
            colspan: 1,
            rowspan: 1,
            jsonAttr: 'nationality',
            title: 'Pays de nationalité',
            inputType: 'oneChoice',
            choices: QuizStaticVariable.COUNTRIES,
            parentJsonAttr: 'noParent',
            valueToBeVisible: [],

          },
          {
            colspan: 1,
            rowspan: 1,
            jsonAttr: 'secNationality',
            title: 'Pays de deuxieme nationalité ',
            inputType: 'oneChoice',
            choices: QuizStaticVariable.COUNTRIES,
            parentJsonAttr: 'noParent',
            valueToBeVisible: [],

          },
          {
            colspan: 1,
            rowspan: 1,
            jsonAttr: 'maritalStatus',
            title: 'Situation Matrimoniale',
            inputType: 'oneChoice',
            choices: QuizStaticVariable.MARITAL_STATUS,
            parentJsonAttr: 'noParent',
            valueToBeVisible: [],

          },
          {
            colspan: 1,
            rowspan: 1,
            jsonAttr: 'clientType',
            title: 'Type du client ',
            inputType: 'oneChoice',
            choices: QuizStaticVariable.CLIENT_TYPE,
            parentJsonAttr: 'noParent',
            valueToBeVisible: [],

          },
          {
            colspan: 1,
            rowspan: 1,
            jsonAttr: 'codeID',
            title: 'Numéro de la pièce d\'identité',
            inputType: 'input',
            parentJsonAttr: 'noParent',
            valueToBeVisible: [],

          },
          {
            colspan: 1,
            rowspan: 1,
            jsonAttr: 'autority',
            title: 'Autorité de délivrance',
            inputType: 'oneChoice',
            choices: QuizStaticVariable.COUNTRIES,
            parentJsonAttr: 'noParent',
            valueToBeVisible: [],
          },
          {
            colspan: 1,
            rowspan: 1,
            jsonAttr: 'emissionDate',
            title: 'Date d\'émission',
            inputType: 'datePicker',
            minDate: new Date(1900, 1, 1),
            maxDate: new Date(),
            parentJsonAttr: 'noParent',
            valueToBeVisible: [],
          },
          {
            colspan: 1,
            rowspan: 1,
            jsonAttr: 'expirationDate',
            title: 'Date d\'expiration',
            inputType: 'datePicker',
            minDate: new Date(),
            maxDate: new Date(2100, 1, 1),
            parentJsonAttr: 'noParent',
            valueToBeVisible: [],
          },
       /*   {

            colspan: 1,
            rowspan: 1,
            jsonAttr: '',
            title: '',
          },*/
          {
            colspan: 1,
            rowspan: 1,
            jsonAttr: 'legalRepresentativeName',
            title: 'Nom du représentant légal \n(si applicable)',
            inputType: 'input',
            parentJsonAttr: 'noParent',
            valueToBeVisible: [],
          },
          {
            colspan: 1,
            rowspan: 1,
            jsonAttr: 'legalRepresentativeQuality',
            title: 'Qualité du représentant légal \n(si applicable)',
            inputType: 'input',
            parentJsonAttr: 'noParent',
            valueToBeVisible: [],

          }
        ]
      },
      {
        title: 'Coordonnées mandataire',
        icon: 'person_pin_circle',
        contentType: 'stardard',
        controlAttr: 'clientContactMandataire',
        elements: [

          {
            colspan: 1,
            rowspan: 1,
            jsonAttr: 'phoneNum',
            title: 'Tél mobile',
            inputType: 'input',
            parentJsonAttr: 'noParent',
            valueToBeVisible: [],

          },
          {
            colspan: 1,
            rowspan: 1,
            jsonAttr: 'FaxNum',
            title: 'Téléphone 2',
            inputType: 'input',
            parentJsonAttr: 'noParent',
            valueToBeVisible: [],

          },
       /*   {
            colspan: 1,
            rowspan: 1,
            jsonAttr: 'email',
            title: 'Adresse e-mail',
            inputType: 'input',
            parentJsonAttr: 'noParent',
            valueToBeVisible: [],

          },*/
/*
          {
            colspan: 1,
            rowspan: 1,
            jsonAttr: 'fatherName',
            title: 'Nom du père',
            inputType: 'input',
            parentJsonAttr: 'noParent',
            valueToBeVisible: [],

          },
          {
            colspan: 1,
            rowspan: 1,
            jsonAttr: 'fatherFname',
            title: 'Prénom du père',
            inputType: 'input',
            parentJsonAttr: 'noParent',
            valueToBeVisible: [],

          },
          {
            colspan: 1,
            rowspan: 1,
            jsonAttr: 'motherName',
            title: 'Nom de la mère',
            inputType: 'input',
            parentJsonAttr: 'noParent',
            valueToBeVisible: [],

          },
          {
            colspan: 1,
            rowspan: 1,
            jsonAttr: 'motherFname',
            title: 'Prénom de la mère',
            inputType: 'input',
            parentJsonAttr: 'noParent',
            valueToBeVisible: [],

          },
*/
          {
            colspan: 1,
            rowspan: 1,
            jsonAttr: 'currentAdress',
            title: 'Adresse de résidence',
            inputType: 'input',
            parentJsonAttr: 'noParent',
            valueToBeVisible: [],

          },
          {
            colspan: 1,
            rowspan: 1,
            jsonAttr: 'zipCodeResidence',
            title: 'code postale résidence',
            inputType: 'input',
            parentJsonAttr: 'noParent',
            valueToBeVisible: [],

          },
          {
            colspan: 1,
            rowspan: 1,
            jsonAttr: 'cityResidence',
            title: 'Ville de résidence',
            inputType: 'input',
            parentJsonAttr: 'noParent',
            valueToBeVisible: [],

          },
          {
            colspan: 1,
            rowspan: 1,
            jsonAttr: 'countryResidence',
            title: 'Pays de résidence',
            inputType: 'oneChoice',
            choices: QuizStaticVariable.COUNTRIES,
            parentJsonAttr: 'noParent',
            valueToBeVisible: [],


          },
          {
            colspan: 2,
            rowspan: 1,
            inputType: 'drawDivider',
            parentJsonAttr: 'noParent',
            valueToBeVisible: [],

          },
          {
            colspan: 2,
            rowspan: 1,
            jsonAttr: 'checkCorrespLegalAdress',
            title: 'Est-ce-que votre adresse de correspondance est différente de votre adresse légale ?',
            inputType: 'oneChoiceWithControl',
            jsonAttrToRemove: 'flag',
            choices: QuizStaticVariable.OUI_NON,
            parentJsonAttr: 'noParent',
            valueToBeVisible: [],

          }
        ]
      },
      {
        title: 'Environnement professionnel du mandataire',
        icon: 'ballot',
        contentType: 'stardard',
        controlAttr: 'clientJobMandataire',
        elements: [
          {
            colspan: 1,
            rowspan: 1,
            jsonAttr: 'clientJobType',
            title: 'Type de profession du client ',
            inputType: 'oneChoiceWithControl',
            jsonAttrToRemove: 'flag',
            parentJsonAttr: 'noParent',
            valueToBeVisible: [],
            choices: QuizStaticVariable.CLIENT_JOB_TYPE,
          },
        ]
      },
      {
        title: 'Environnement bancaire ',
        icon: 'account_balance',
        contentType: 'stardard',
        controlAttr: 'clientBankMandataire',
        addElement: false,
        addElementKey: 'EVB',
        addElementIndexMax: 1,
        addElementIndex: 1,
        elements: [
          {
            colspan: 1,
            rowspan: 1,
            jsonAttr: 'EVBank1',
            title: 'BANQUE',
            inputType: 'oneChoiceWithText',
            choices: QuizStaticVariable.EV_BANK,
            parentJsonAttr: 'noParent',
            valueToBeVisible: [],
          },
          {
            colspan: 1,
            rowspan: 1,
            jsonAttr: 'EVAgency1',
            title: 'Agence bancaire',
            inputType: 'input',
            parentJsonAttr: 'noParent',
            valueToBeVisible: [],
          },
          {
            colspan: 1,
            rowspan: 1,
            jsonAttr: 'EVCity1',
            title: 'ville',
            inputType: 'input',
            parentJsonAttr: 'noParent',
            valueToBeVisible: [],
          },
          {
            colspan: 1,
            rowspan: 1,
            jsonAttr: 'EVRIB1',
            title: 'RIB',
            inputType: 'input',
            parentJsonAttr: 'noParent',
            valueToBeVisible: [],
          }
        ]
      },
      {
        title: 'Activité du mandataire',
        icon: 'trending_up',
        contentType: 'stardard',
        controlAttr: 'clientActivityMandataire',
        elements: [
          {
            colspan: 1,
            rowspan: 1,
            jsonAttr: 'job',
            title: 'Profession',
            inputType: 'oneChoice',
            choices: QuizStaticVariable.JOB,
            parentJsonAttr: 'noParent',
            valueToBeVisible: [],

          },
          {
            colspan: 1,
            rowspan: 1,
            jsonAttr: 'employer',
            title: 'Employeur',
            inputType: 'input',
            parentJsonAttr: 'noParent',
            valueToBeVisible: [],

          },
          {
            colspan: 1,
            rowspan: 1,
            jsonAttr: 'employerAdress',
            title: 'Adresse de l\'employeur',
            inputType: 'input',
            valueToBeVisible: [],
          },
          {
            jsonAttr: 'employerZibCode',
            title: 'code postal',
            inputType: 'input',
            valueToBeVisible: [],
            colspan: 1,
            rowspan: 1,

          },
          {
            jsonAttr: 'employerCity',
            title: 'Ville',
            inputType: 'input',
            valueToBeVisible: [],
            colspan: 1,
            rowspan: 1,

          },
          {
            valueToBeVisible: [],
            colspan: 1,
            rowspan: 1,
            jsonAttr: 'employerCountry',
            title: 'pays',
            inputType: 'oneChoice',
            choices: QuizStaticVariable.COUNTRIES,
          },
          {
            colspan: 1,
            rowspan: 1,
            jsonAttr: 'activitySector',
            title: 'Secteur d\'activité',
            inputType: 'oneChoice',
            choices: QuizStaticVariable.ACTIVITY_TYPE,
            parentJsonAttr: 'noParent',
            valueToBeVisible: [],


          }, {
            colspan: 1,
            rowspan: 1,
            jsonAttr: 'seniority',
            title: 'Ancienneté (nombre d\'année)',
            inputType: 'numberPicker',
            parentJsonAttr: 'noParent',
            valueToBeVisible: [],

          },
          {
            colspan: 1,
            rowspan: 1,
            jsonAttr: 'startingDateForProfessionals',
            title: 'Date début de l\'activité pour les professionels',
            inputType: 'datePicker',
            minDate: new Date(1900, 1, 1),
            maxDate: new Date(),
            parentJsonAttr: 'noParent',
            valueToBeVisible: [],
          },
          {
            colspan: 1,
            rowspan: 1,
            jsonAttr: 'geographicPeremeter',
            title: 'Zone d\'activité du client',
            inputType: 'oneChoice',
            choices: QuizStaticVariable.COUNTRIES,
            parentJsonAttr: 'noParent',
            valueToBeVisible: [],
          }, {
            colspan: 1,
            rowspan: 1,
            jsonAttr: 'otherActivities',
            title: 'Autre activité',
            inputType: 'input',
            parentJsonAttr: 'noParent',
            valueToBeVisible: [],
          }
        ]
      },
      {
        title: 'Dépositaire mandataire',
        icon: 'person_search',
        contentType: 'stardard',
        controlAttr: 'depositoryMandataire',
        elements: [
          {
            colspan: 1,
            rowspan: 1,
            jsonAttr: 'depositary',
            title: 'Dépositaire ',
            inputType: 'oneChoice',
            choices: QuizStaticVariable.DEPOSITAIRE,
            parentJsonAttr: 'noParent',
            valueToBeVisible: [],
          },
          {
            colspan: 1,
          },

          /*{
            colspan: 1,
            rowspan: 1,
            jsonAttr: 'depositionAtBKB',
            title: 'Disposition d’un compte ouvert chez BKB',
            inputType: 'oneChoiceWithText',
            inputTextVisible: false,
            hintText: 'N° compte BKB',
            choices: QuizStaticVariable.OUI_NON_WITH_TEXT,
            parentJsonAttr: 'noParent',
            valueToBeVisible: [],
          }*/
        ]
      },
      {
        title: 'Attributs FATCA (Foreign Account Tax Compliance)',
        icon: 'person_search',
        contentType: 'stardard',
        controlAttr: 'FATCAMandataire',
        elements: [
          {
            colspan: 1,
            rowspan: 1,
            jsonAttr: 'FATCANationality',
            title: 'Etes-vous un citoyen américain (Nationalité ou Résidence) ?',
            inputType: 'oneChoice',
            choices: QuizStaticVariable.OUI_NON,
            parentJsonAttr: 'noParent',
            valueToBeVisible: [],

          }, {
            colspan: 1,
            rowspan: 1,
            jsonAttr: 'FATCAOtherNationality',
            title: 'Avez-vous nationalité américaine ?',
            inputType: 'oneChoice',
            choices: QuizStaticVariable.OUI_NON,
            parentJsonAttr: 'noParent',
            valueToBeVisible: [],
          },
          {
            colspan: 1,
            rowspan: 1,
            jsonAttr: 'FATCAAdress',
            title: 'Avez-vous une adresse/domicile/courrier aux USA ?',
            inputType: 'oneChoiceWithText',
            inputTextVisible: false,
            hintText: 'Adresse',
            choices: QuizStaticVariable.OUI_NON_WITH_TEXT,
            parentJsonAttr: 'noParent',
            valueToBeVisible: [],
          },
          {
            colspan: 1,
            rowspan: 1,
            jsonAttr: 'FATCAPhone',
            title: 'Avez vous une ligne téléphonique américaine ?',
            inputType: 'oneChoiceWithText',
            inputTextVisible: false,
            hintText: 'Numéro de téléphone',
            choices: QuizStaticVariable.OUI_NON_WITH_TEXT,
            parentJsonAttr: 'noParent',
            valueToBeVisible: [],
          },
          {
            colspan: 1,
            rowspan: 1,
            jsonAttr: 'FATCAAttorney',
            title: 'Avez-vous octoyé une procuration ou un pouvoir de signature en cours de validitéç à une personne US ?',
            inputType: 'oneChoiceWithText',
            inputTextVisible: false,
            hintText: 'Nom et prénom de la personne',
            choices: QuizStaticVariable.OUI_NON_WITH_TEXT,
            parentJsonAttr: 'noParent',
            valueToBeVisible: [],
          },
          {
            colspan: 1,
            rowspan: 1,
            jsonAttr: 'FATCAMoneytransfer',
            title: 'Effectuez-vous un virement permanent sur un ou plusieurs comptes aux USA ?',
            inputType: 'oneChoice',
            choices: QuizStaticVariable.OUI_NON,
            parentJsonAttr: 'noParent',
            valueToBeVisible: [],
          },
          {
            colspan: 1,
            rowspan: 1,
            jsonAttr: 'FATCAAccountUSA',
            title: 'Disposez-vous de comptes aux USA ?',
            inputType: 'oneChoice',
            choices: QuizStaticVariable.OUI_NON,
            parentJsonAttr: 'noParent',
            valueToBeVisible: [],
          },
          {
            colspan: 1,
            rowspan: 1,
            jsonAttr: 'FATCAGreenCard',
            title: 'Disposez-vous d\'une Green Card ?',
            inputType: 'oneChoiceWithText',
            inputTextVisible: false,
            hintText: 'Green Card n°',
            choices: QuizStaticVariable.OUI_NON_WITH_TEXT,
            parentJsonAttr: 'noParent',
            valueToBeVisible: [],
          },
        ]
      },
      {
        title: 'Personne Politiquement Exposée (PPE)',
        icon: 'person_outline',
        contentType: 'stardard',
        controlAttr: 'PPEMandataire',
        elements: [
          {
            jsonAttr: 'PPEStatut',
            title: ' Êtes-vous PPE ?',
            inputType: 'oneChoiceWithControl',
            choices: QuizStaticVariable.OUI_NON,
            parentJsonAttr: 'noParent',
            jsonAttrToRemove: 'PPERelativeStatut',
            colspan: 1,
            rowspan: 1,
          },
        ]
      },
      {
        title: 'Situation financière et patrimoniale',
        icon: 'beach_access',
        contentType: 'stardard',
        controlAttr: 'financialSituationMandataire',
        elements: [
          {
            colspan: 2,
            rowspan: 1,
            jsonAttr: 'revenues',
            title: 'A combien estimez-vous le montant de vos revenus annuels moyens brut ?',
            inputType: 'oneChoice',
            choices: QuizStaticVariable.REVENUES,
            parentJsonAttr: 'noParent',
            valueToBeVisible: [],
          },
          {
            colspan: 2,
            rowspan: 1,
            jsonAttr: 'revenuesSource',
            title: 'Quels sont vos principaux sources de revenus ?',
            inputType: 'multipleChoiceWithText',
            choices: QuizStaticVariable.REVENUES_SOURCES,
            parentJsonAttr: 'noParent',
            valueToBeVisible: [],
          },
          {
            colspan: 2,
            rowspan: 1,
            jsonAttr: 'patrmonyDistribution',
            title: 'Quelles sont les composantes de votre patrimoine?',
            inputType: 'multipleChoiceWithText',
            choices: QuizStaticVariable.PATRMONY_DISTRIBUTION,
            parentJsonAttr: 'noParent',
            valueToBeVisible: [],
          },
        ]
      },
      {
        title: 'Connaissance en matière d\'investissement',
        icon: 'engineering',
        contentType: 'stardard',
        controlAttr: 'investementKnowledgeMandataire',
        elements: [
          {
            colspan: 2,
            rowspan: 1,
            jsonAttr: 'purpose',
            title: 'Objet de la relation',
            inputType: 'multipleChoiceWithText',
            inputTextVisible: false,
            choices: QuizStaticVariable.RELATION_PURPOSE,
            parentJsonAttr: 'noParent',
            valueToBeVisible: [],

          },
          {
            colspan: 2,
            rowspan: 1,
            jsonAttr: 'investementHorizon',
            title: 'Quel est votre horizon d\'investissement ?',
            inputType: 'oneChoiceWithText',
            choices: QuizStaticVariable.INVESTEMENT_HORIZON,
            parentJsonAttr: 'noParent',
            valueToBeVisible: [],
          },
          {
            colspan: 2,
            rowspan: 1,
            jsonAttr: 'interestedSectors',
            title: 'A priori, quels sont les secteurs des sociétés cotées qui vous intéressent ?',
            inputType: 'multipleChoiceWithText',
            choices: QuizStaticVariable.INTERESTED_SECTORS,
            parentJsonAttr: 'noParent',
            valueToBeVisible: [],
          },
          {
            colspan: 2,
            rowspan: 1,
            jsonAttr: 'transaction',
            title: 'Quels sont les produits financiers qui vous intéressent ?',
            inputType: 'multipleChoiceWithText',
            choices: QuizStaticVariable.TRANSACTION,
            parentJsonAttr: 'noParent',
            valueToBeVisible: [],
          },
          {
            colspan: 2,
            rowspan: 1,
            jsonAttr: 'profitPolitics',
            title: 'Dans votre politique de profits, optez-vois pour :',
            inputType: 'multipleChoiceWithText',
            choices: QuizStaticVariable.PROFIT_POLITICS,
            parentJsonAttr: 'noParent',
            valueToBeVisible: [],
          },
          {
            colspan: 2,
            rowspan: 1,
            jsonAttr: 'investorProfil',
            title: 'Parmi les descriptifs suivants, lequel correspond le mieux à votre profil d\'investisseur ?',
            inputType: 'oneChoice',
            choices: QuizStaticVariable.INVESTOR_PROFIL,
            parentJsonAttr: 'noParent',
            valueToBeVisible: [],
          },
          {
            colspan: 2,
            rowspan: 1,
            jsonAttr: 'investorKnowledge',
            title: 'Quel est votre niveau de connaissances en matière d\'investissement sur le marché financier ?',
            inputType: 'oneChoice',
            choices: QuizStaticVariable.INVESTOR_KNOWLEDGE,
            parentJsonAttr: 'noParent',
            valueToBeVisible: [],
          },
          {
            colspan: 2,
            rowspan: 1,
            jsonAttr: 'investorInterventions',
            title: 'Votre intervention en Bourse sera effectuées :',
            inputType: 'multipleChoiceWithText',
            choices: QuizStaticVariable.INVESTOR_INTERVENTIONS,
            parentJsonAttr: 'noParent',
            valueToBeVisible: [],
          },
          {
            colspan: 2,
            rowspan: 1,
            jsonAttr: 'portfolioRotation',
            title: 'A combien estimez-vous la rotation de votre protefeuille durant les deux dernières années ?',
            inputType: 'oneChoice',
            choices: QuizStaticVariable.PORTFOLIO_ROTATION,
            parentJsonAttr: 'noParent',
            valueToBeVisible: [],
          },

          {
            colspan: 2,
            rowspan: 1,
            jsonAttr: 'moneyLost',
            title: 'Avez-vous déjà perdu des sommes significatives durant les deux dernières années ',
            inputType: 'oneChoice',
            choices: QuizStaticVariable.MONEY_LOST,
            parentJsonAttr: 'noParent',
            valueToBeVisible: [],
          },
          {
            colspan: 2,
            rowspan: 1,
            jsonAttr: 'effectiveBeneficiary',
            title: 'Etes-vous le bénéficiare effectif des opérations à exécuter ?',
            inputType: 'oneChoiceWithText',
            choices: QuizStaticVariable.EFFECTIVE_BENEFICIARY,
            parentJsonAttr: 'noParent',
            valueToBeVisible: [],
          }

        ]
      },

      /*
            {
                title:"Visualiser le questionnaire",
                controlAttr: "visualizeContent",
            }
                */
    ]
  },
  DATA_TO_ADD: {
    'flag': {
      '1': [],
      '2': []
    },
    'checkCorrespLegalAdress': {
      '1': [{
        colspan: 1,
        rowspan: 1,
        jsonAttr: 'correspAdress',
        title: 'Adresse de correspondance',
        inputType: 'input',
        parentJsonAttr: 'noParent',
        valueToBeVisible: [],

      },
        {
          colspan: 1,
          rowspan: 1,
          jsonAttr: 'correspZipCode',
          title: 'Code postale correspondance',
          inputType: 'input',
          parentJsonAttr: 'noParent',
          valueToBeVisible: [],

        },
        {
          colspan: 1,
          rowspan: 1,
          jsonAttr: 'correspCity',
          title: 'Ville de correspondance',
          inputType: 'input',
          parentJsonAttr: 'noParent',
          valueToBeVisible: [],

        },
        {
          colspan: 1,
          rowspan: 1,
          jsonAttr: 'correspCountry',
          title: 'Pays de correspondance',
          inputType: 'oneChoice',
          choices: QuizStaticVariable.COUNTRIES,
          parentJsonAttr: 'noParent',
          valueToBeVisible: [],

        },

        {
          colspan: 1,
          rowspan: 1,
          jsonAttr: 'countryTaxResidence',
          title: 'Pays/Résidence fiscale',
          inputType: 'oneChoice',
          choices: QuizStaticVariable.COUNTRIES,
          parentJsonAttr: 'noParent',
          valueToBeVisible: [],

        }],
      '2': []
    },
    'EVB': [
      {
        colspan: 1,
        rowspan: 1,
        jsonAttr: 'EVBank2',
        title: 'Banque 2',
        inputType: 'OneChoice',
        choices: QuizStaticVariable.EV_BANK,

      },
      {
        colspan: 1,
        rowspan: 1,
        jsonAttr: 'EVAgency2',
        title: 'Agence bancaire 2',
        inputType: 'input',
      },
      {
        colspan: 1,
        rowspan: 1,
        jsonAttr: 'EVCity2',
        title: 'ville 2',
        inputType: 'input',
      },
      {
        colspan: 1,
        rowspan: 1,
        jsonAttr: 'EVRIB2',
        title: 'RIB 2',
        inputType: 'input',
      }
    ],
    'PPEStatut': {
      '1': [{
        jsonAttr: 'PPEJob',
        title: 'Profession PPE',
        inputType: 'input',
        parentJsonAttr: 'noParent',
        valueToBeVisible: 1,
        colspan: 1,
        rowspan: 1,
      },
        {

          jsonAttr: 'PPEJobCountry',
          title: 'Pays travail PPE',
          inputType: 'oneChoice',
          choices: QuizStaticVariable.COUNTRIES,
          parentJsonAttr: 'noParent',
          valueToBeVisible: 1,
          colspan: 1,
          rowspan: 1,
        },
        {
          jsonAttr: 'PPEEndDate',
          title: 'Date de fin du statut',
          inputType: 'datePicker',
          minDate: new Date(),
          maxDate: new Date(2100, 1, 1),
          parentJsonAttr: 'noParent',
          valueToBeVisible: 1,
          colspan: 1,
          rowspan: 1,


        },
        {
          jsonAttr: 'PPERelativeStatut',
          title: 'Statut Proche PPE',
          inputType: 'oneChoiceWithControl',
          jsonAttrToRemove: 'flag',
          choices: QuizStaticVariable.OUI_NON,
          parentJsonAttr: 'noParent',
          valueToBeVisible: 2,
          colspan: 1,
          rowspan: 1,
        }],
      '2': [{
        jsonAttr: 'PPERelativeStatut',
        title: 'Statut Proche PPE',
        inputType: 'oneChoiceWithControl',
        jsonAttrToRemove: 'flag',
        choices: QuizStaticVariable.OUI_NON,
        parentJsonAttr: 'noParent',
        valueToBeVisible: 2,
        colspan: 1,
        rowspan: 1,
      }],
    },
    'PPERelativeStatut': {
      '1': [
        {
          jsonAttr: 'PPEName',
          title: 'Prénom PPE',
          inputType: 'input',
          parentJsonAttr: 'noParent',
          valueToBeVisible: 1,
          colspan: 1,
          rowspan: 1,

        },

        {
          jsonAttr: 'PPEFname',
          title: 'Nom PPE',
          inputType: 'input',
          parentJsonAttr: 'noParent',
          valueToBeVisible: 1,
          colspan: 1,
          rowspan: 1,

        },
        {
          jsonAttr: 'PPEBirthDate',
          title: 'Date naissance PPE',
          inputType: 'datePicker',
          minDate: new Date(1900, 1, 1),
          maxDate: new Date(),
          parentJsonAttr: 'noParent',
          valueToBeVisible: 1,
          colspan: 1,
          rowspan: 1,


        },
        {
          jsonAttr: 'PPEBirthPlace',
          title: 'Lieu naissance PPE',
          inputType: 'input',
          parentJsonAttr: 'noParent',
          valueToBeVisible: 1,
          colspan: 1,
          rowspan: 1,

        },

        {
          jsonAttr: 'PPERelativeType',
          title: 'Catégorie du PPE',
          inputType: 'oneChoice',
          choices: [{text: 'Cercle familial', value: 1}, {text: 'Cercle d\'influence', value: 2}],
          valueToBeVisible: 1,
          colspan: 1,
          rowspan: 1,

        },
        {
          colspan: 1,
          rowspan: 1,
          jsonAttr: 'PPENationalityRelative',
          title: 'Nationalité du PPE',
          inputType: 'oneChoice',
          choices: QuizStaticVariable.COUNTRIES,
        },
        {
          colspan: 1,
          rowspan: 1,
          jsonAttr: 'PPECountryResidence',
          title: 'Pays de Résidence du PPE',
          inputType: 'oneChoice',
          choices: QuizStaticVariable.COUNTRIES,
        },

        {
          jsonAttr: 'PPEJobRelative',
          title: 'Profession Proche PPE',
          inputType: 'input',
          parentJsonAttr: 'noParent',
          valueToBeVisible: 1,
          colspan: 1,
          rowspan: 1,

        },

        {
          jsonAttr: 'PPEJobCountryRelative',
          title: 'Pays travail Proche PPE',
          inputType: 'oneChoice',
          choices: QuizStaticVariable.COUNTRIES,
          parentJsonAttr: 'noParent',
          valueToBeVisible: 1,
          colspan: 1,
          rowspan: 1,

        },
        {
          jsonAttr: 'PPEEndDateRelative',
          title: 'Date de fin du statut',
          inputType: 'datePicker',
          parentJsonAttr: 'noParent',
          valueToBeVisible: 1,
          colspan: 1,
          rowspan: 1,

        },
        {
          jsonAttr: 'PPEIdEntity',
          title: 'N° Pièce d\'identité du PPE',
          inputType: 'input',
          parentJsonAttr: 'noParent',
          valueToBeVisible: 1,
          colspan: 1,
          rowspan: 1,

        },
        {
          jsonAttr: 'PPEDeliveranceCountry',
          title: 'Pays de délivrance',
          inputType: 'oneChoice',
          choices: QuizStaticVariable.COUNTRIES,
          parentJsonAttr: 'noParent',
          valueToBeVisible: 1,
          colspan: 1,
          rowspan: 1,

        },
        {
          jsonAttr: 'PPEEmissionDate',
          title: 'Date d\'émission',
          inputType: 'datePicker',
          minDate: new Date(1900, 1, 1),
          maxDate: new Date(),
          parentJsonAttr: 'noParent',
          valueToBeVisible: 1,
          colspan: 1,
          rowspan: 1,
        },
        {
          jsonAttr: 'PPEExpirationDate',
          title: 'Date d\'expiration',
          inputType: 'datePicker',
          minDate: new Date(1900),
          maxDate: new Date(2100, 1, 1),
          parentJsonAttr: 'noParent',
          valueToBeVisible: 1,
          colspan: 1,
          rowspan: 1,


        }
      ],
      '2': []
    },
    'clientJobType': {
      '1': [
        {
          colspan: 1,
          rowspan: 1,
          jsonAttr: 'RC',
          title: 'N° du RC pour les commerçants',
          inputType: 'input',
          parentJsonAttr: 'noParent',
          valueToBeVisible: 1,
        },
        {
          colspan: 1,
          rowspan: 1,
          jsonAttr: 'registrationCourt',
          title: 'Tribunal d’immatriculation/Lieu de délivrance',
          inputType: 'input',
          parentJsonAttr: 'noParent',
          valueToBeVisible: 1,
        },
        {
          colspan: 1,
          rowspan: 1,
          jsonAttr: 'NProfessionalTax',
          title: 'N°de la taxe professionnels',
          inputType: 'input',
          parentJsonAttr: 'noParent',
          valueToBeVisible: 1,
        },
        {
          colspan: 1,
          rowspan: 1,
          jsonAttr: 'ICE',
          title: 'N°ICE',
          inputType: 'input',
          parentJsonAttr: 'noParent',
          valueToBeVisible: 1,
        }

      ],
      '2': [
        {
          colspan: 1,
          rowspan: 1,
          jsonAttr: 'RNAE',
          title: 'N° du Registre National de l’Auto-Entrepreneur',
          inputType: 'input',
          parentJsonAttr: 'noParent',
          valueToBeVisible: 2,
        },
        {
          colspan: 1,
          rowspan: 1,
          jsonAttr: 'contractDenom',
          title: 'Dénomination',
          inputType: 'input',
          parentJsonAttr: 'noParent',
          valueToBeVisible: 2,
        },
        {
          colspan: 1,
          rowspan: 1,
          jsonAttr: 'contractImmatCentre',
          title: 'Centre d\'immatriculation',
          inputType: 'input',
          parentJsonAttr: 'noParent',
          valueToBeVisible: 2,
        },
        {
          colspan: 1,
          rowspan: 1,
          jsonAttr: 'contractNumPatente',
          title: 'N° de patente',
          inputType: 'input',
          parentJsonAttr: 'noParent',
          valueToBeVisible: 2,
        }
      ],
      '3':[]

    },
    'FRCStatut': {
      '1': [{
        colspan: 1,
        rowspan: 1,
        jsonAttr: 'FRCName',
        title: 'Prénom du mandataire',
        inputType: 'input',
        parentJsonAttr: 'noParent',
        valueToBeVisible: [],

      },
        {
          colspan: 1,
          rowspan: 1,
          jsonAttr: 'FRCFname',
          title: 'Nom du mandataire',
          inputType: 'input',
          parentJsonAttr: 'noParent',
          valueToBeVisible: [],

        },
        {
          colspan: 1,
          rowspan: 1,
          jsonAttr: 'FRCRelation',
          title: 'Nature de la relation',
          inputType: 'input',
          parentJsonAttr: 'noParent',
          valueToBeVisible: [],
        },
        {
          colspan: 1,
          rowspan: 1,
          jsonAttr: 'FRCNationality',
          title: 'Pays de nationalité du mandataire',
          inputType: 'oneChoice',
          choices: QuizStaticVariable.COUNTRIES,
          parentJsonAttr: 'noParent',
          valueToBeVisible: [],
        },
        {
          colspan: 1,
          rowspan: 1,
          jsonAttr: 'FRCResidence',
          title: 'Pays de résidence du mandataire',
          inputType: 'oneChoice',
          choices: QuizStaticVariable.COUNTRIES,
          parentJsonAttr: 'noParent',
          valueToBeVisible: [],
        },
        {
          colspan: 1,
          rowspan: 1,
          jsonAttr: 'FRCIdType',
          title: 'Piece d\'identité',
          inputType: 'oneChoice',
          choices: QuizStaticVariable.ID_TYPE,
          parentJsonAttr: 'noParent',
          valueToBeVisible: [],
          indication:"Joindre votre pièce d'identité"

        },
        {
          colspan: 1,
          rowspan: 1,
          jsonAttr: 'FRCIdCode',
          title: 'Numéro de la pièce d\'identité',
          inputType: 'input',
          parentJsonAttr: 'noParent',
          valueToBeVisible: [],
        },
        {
          colspan: 1,
          rowspan: 1,
          jsonAttr: 'FRCDeliver',
          title: 'Pays de Délivrance',
          inputType: 'oneChoice',
          choices: QuizStaticVariable.COUNTRIES,
          parentJsonAttr: 'noParent',
          valueToBeVisible: [],
        },
        {
          colspan: 1,
          rowspan: 1,
          jsonAttr: 'FRCDate',
          title: 'Date de validité',
          inputType: 'datePicker',
          minDate: new Date(1900, 1, 1),
          maxDate: new Date(),
          parentJsonAttr: 'noParent',
          valueToBeVisible: [],

        },
        {
          colspan: 1,
          rowspan: 1,
          jsonAttr: 'FRCValidity',
          title: 'Fin de validité',
          inputType: 'datePicker',
          minDate: new Date(),
          maxDate: new Date(2100, 1, 1),
          parentJsonAttr: 'noParent',
          valueToBeVisible: [],

        },
        {
          colspan: 1,
          rowspan: 1,
          jsonAttr: 'FRCCode',
          title: 'Code Client BKB du mandataire \n(si applicable )',
          inputType: 'input',
          parentJsonAttr: 'noParent',
          valueToBeVisible: [],
        }],
      '2': []
    }
  },
};

