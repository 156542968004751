import { Component, Input, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { StepperService } from '../../stepper.service';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';


@Component({
  selector: 'app-indication-main',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.css']
})
export class LandingPageComponent implements OnInit {

  isSamsungBrowser: boolean = false;

  patientCategory: FormGroup;
  @Input('indication') indication = 'Un email vous sera envoyé afin de confirmer vos coordonnées électroniques';

  display = false;
  displayButtonOpenAccount = false

  constructor(
    private router: Router,
    private stepperServoce: StepperService,
    private fb: FormBuilder,
    private stepperService: StepperService,
    @Inject(DOCUMENT) private document: any
  ) {
  }


  openSansMandataire() {
    this.displayButtonOpenAccount = true
    this.stepperService.loadingWorkflow = true
    localStorage.setItem('mandataire', 'no');
    this.stepperServoce.init();
    this.stepperService.mandataire = false;
  }

  ngOnInit(): void {
    if ((localStorage.getItem('token') || localStorage.getItem('mail_token'))) {
      this.router.navigate(['account']);
    } else {
      this.display = true;
    }

    const userAgent: string = this.document.defaultView.navigator.userAgent;
    /*   console.log(userAgent) */
    if (userAgent.includes('SamsungBrowser') && userAgent.includes('Mobile')) {
      /*   console.log("userAgent") */
      this.isSamsungBrowser = true;
    }
  }

}
