import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';
import {WORKFLOW_STEPS} from './shared/GlobalVars';
import {DataToShow} from './main-stepper/main-stepper/variable';

const
  CIN_INDEX = 0,
  GENERALITE_INDEX = 1,
  COORDONNEE_INDEX = 2,
  JOB_INDEX = 3,
  BANK_INDEX = 4,
  ACTIVITY_INDEX = 5,
  DEPOSITORY_INDEX = 6,
  FATCA_INDEX = 7,
  PPE_INDEX = 8,
  FINANCIAL_SITUATION_INDEX = 9,
  INVESTEMENT_KNOWLEDGE_INDEX = 10,
  OFFRED_SERVICES_INDEX = 11;

export const
  ELIGIBILITE = -1,
  CATEGORIES = 0,
  CATEGORIES_MANDATIRE = 1,
  SERVICES = 2,
  RDV_DATE = 3,
  CHECK_CONTRAT = 4,
  BIENVENUE_BK = 5;


@Injectable({
  providedIn: 'root',
})
export class StepperService {

  currentStepp: any = {};
  currentStepNumber = 0;
  step: any = WORKFLOW_STEPS.ELIGIBILITE;
  goToStepN: Subject<any> = new Subject<any>();

  goToNextGlobalStep: Subject<any> = new Subject<any>();
  stepsError: any = [];
  mandataire = false;
  doneCategories = false;
  doneCategoriesMandataire = false;
  doneServices = false;
  doneCheckContrat = false;
  stepValidation = [];
  stepMandataireValidation = [];
  stepContratValidation = [];
  selectedMainStep = ELIGIBILITE;
  dataToShow = DataToShow.DATA_TO_SHOW;
  dataToShowAttorny = DataToShow.DATA_TO_SHOW_ATTORNY;
  loadingWorkflow = false;
  loadingData = false;
  doneRdv=false

  mappingStepper() {
    switch (this.selectedMainStep) {
      case ELIGIBILITE:
        this.step = WORKFLOW_STEPS.ELIGIBILITE;
        this.currentStepp = this.dataToShow[this.step][this.currentStepNumber];
        return;
      case CATEGORIES:
        this.step = WORKFLOW_STEPS.CATEGORIES;
        this.currentStepp = this.dataToShow[this.step][this.currentStepNumber];
        return;
      case CATEGORIES_MANDATIRE:
        this.step = WORKFLOW_STEPS.CATEGORIES_MANDATIRE;
        this.doneCategories = true;
        this.currentStepp = this.dataToShowAttorny[this.step][this.currentStepNumber];
        return;
      case SERVICES:
        this.step = WORKFLOW_STEPS.SERVICES;
        this.doneCategoriesMandataire = true;
        this.doneCategories = true;
        this.currentStepNumber = 0;
        this.currentStepp = this.dataToShow[this.step][this.currentStepNumber];
        return;
      case RDV_DATE:
        this.step = WORKFLOW_STEPS.RDV_DATE;
        this.doneCategoriesMandataire = true;
        this.doneCategories = true;
        this.doneServices = true;
        this.currentStepNumber = 0;
        this.currentStepp = this.dataToShow[this.step][this.currentStepNumber];
        return;
      case CHECK_CONTRAT:
        if (this.mandataire) {
          this.step = WORKFLOW_STEPS.CHECK_CONTRAT_MANDATAIRE;
        } else {
          this.step = WORKFLOW_STEPS.CHECK_CONTRAT;
        }

        this.currentStepp = this.dataToShow[this.step][this.currentStepNumber];
        this.doneCategoriesMandataire = true;
        this.doneCategories = true;
        this.doneRdv = true;
        this.doneServices = true;
        return;
      case BIENVENUE_BK:
        this.step = WORKFLOW_STEPS.BIENVENUE_BK;
        this.doneCategoriesMandataire = true;
        this.doneCategories = true;
        this.doneServices = true;
        this.doneRdv = true;
        this.currentStepNumber = 0;
        this.currentStepp = this.dataToShow[this.step][this.currentStepNumber];
        return;
    }
    this.loadingData=true
  }

  constructor() {
    this.currentStepp = this.dataToShow[this.step][this.currentStepNumber];
  }

  init() {
  }

}
